import { FormLabel, Grid, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../../../Core/Controls/Tabs';
import ReportingBudgetTable from './budgetTable';
import { getReportingBudgetScreenDropDown } from '../../../../../Core/Service/ReportScreens-service';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../../Core/FormInput';
import { useRef } from 'react';

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Bugdet', active: true }
];

const ReportingBudget = (props) => {
    const childFunc = useRef(null);
    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: props.branchCode || '',
        branchName: props.branchName || '',
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: [],
        budgetYear: [],
        budgetType: [],
        isValueChange: false
    });

    useEffect(async () => {
        let res = await getReportingBudgetScreenDropDown();
        setState((st) => {
            let newSt = { ...st };
            newSt.budgetYear = res.data.year.map((o) => {
                return { description: `${o.name}`, id: `${o.id}` };
            });
            newSt.budgetType = res.data.type || [];

            return newSt;
        });
    }, []);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const handleActionTriggerCheck = (value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isValueChange = value;
            return newSt;
        });
    };

    const tabs = state.budgetType.map((q) => {
        return {
            label: `${q.name}`,
            body: <ReportingBudgetTable childFunc={childFunc} type={q} yearCode={state.yearCode} handleActionTriggerCheck={handleActionTriggerCheck} />
        };
    });

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <FormLabel>Select Year&nbsp;</FormLabel>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        disableClearable
                                        autoSelect
                                        options={state.budgetYear}
                                        name="Years"
                                        value={state.yearCode}
                                        onChange={handleAutoChange('yearCode')}
                                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SecondaryButton className="setFullWidth" onClick={() => childFunc.current()} disabled={!state.isValueChange}>
                                Save
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <TabComponent tabList={tabs} showWarning={state.isValueChange} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ReportingBudget;
