import React from 'react';
import { Grid } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const HomeAccDealer = React.memo((props) => {
    const { state, setState } = props;
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink onChange={setField} value={getField('service24HomeAccountDealerName')} label="Name" name="service24HomeAccountDealerName" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    onChange={setField}
                    value={getField('service24HomeAccountDealerContactName')}
                    label="Contact Name"
                    name="service24HomeAccountDealerContactName"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    onChange={setField}
                    value={getField('service24HomeAccountDealerOrderNo')}
                    label="Order No"
                    name="service24HomeAccountDealerOrderNo"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    type="number"
                    onChange={setField}
                    value={getField('service24HomeAccountDealerLimit')}
                    label="Limit"
                    name="service24HomeAccountDealerLimit"
                />
            </Grid>
        </Grid>
    );
});
