import { IconButton } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { GridOverlay } from "@material-ui/data-grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../../Core/FormInput";
import DataGridComp from "../../../Core/Grid";
import { getVideos } from "../../../Core/Service/video-service";
import AddVideoModal from "./VideoListModal";

const VideoList = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        showLoader: true,
        VideoDetails: null,
    });

    const VideoCategoryDetails = props.VideoCategoryDetails;

    useEffect(async () => {
        pullVideosAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                VideoDetails: params.value.row,
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Video" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                VideoDetails: null,
                show: true,
            };
        });
    };

    const column_new = [
        {
            field: "id",
            headerName: "ID",
            width: 130,
            sortable: false,
            hide: true,
        },
        {
            field: "",
            headerName: "Action",
            sortable: false,
            width: 100,
            renderCell: (params) => <Arrow value={params} />,
        },
        {
            field: "videoTitle",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "videoURL",
            headerName: "URL",
            flex: 1,
        },
        {
            field: "videoActive",
            headerName: "Active",
            flex: 1,
        }
    ];

    const pullVideosAndUpdateState = async () => {
        let result = await getVideos();

        let sortedRows = [];
        result.data.list.map((row) => row.videoCategoryID == VideoCategoryDetails.id && sortedRows.push(row));

        if (result.success) {
            if (result.success) {
                setState((st) => ({
                    ...st,
                    rows: sortedRows.map((w) => ({
                        ...w,
                        id: w.videoID,
                        videoTitle: w.videoTitle,
                        videoURL: w.videoURL,
                        videoCategoryID: w.videoCategoryID,
                        videoActive: w.videoActive,
                        videoThumbnail: w.videoThumbnail,
                    })),
                    show: false,
                    showDel: false,
                    showLoader: false,
                }));
            }
        }
        if (!result.success) {
            console.log(result.message)
            // showToast(result.message);
        }
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Video available</div>
            </GridOverlay>
        );
    }

    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false }));
        refreshGrid && pullVideosAndUpdateState();
    };

    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Video
                </SecondaryButton>
            </div>
            {state.show ? (
                <AddVideoModal onFormSubmit={modalClosed} VideoDetails={state.VideoDetails} VideoCategoryDetails={VideoCategoryDetails} />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Link color="inherit" to={"/Lists/VideoCategories"} onClick={props.onCloseFunc}>
                    Video Categories
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    {VideoCategoryDetails.videoCategoryDescription}
                </Typography>
            </Breadcrumbs>
            {state.showLoader && (
                <Typography variant="h5">
                    Loading Videos
                </Typography>
            )}
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default VideoList;
