import React from 'react';
import singleAxel from './VehicleDiagrams/images1.png';
import doubleAxelOne from './VehicleDiagrams/axImage01.png';
import doubleAxelTwo from './VehicleDiagrams/azImage02.png';
import doubleAxelThree from './VehicleDiagrams/axImage03.png';
import { TextBox, SecondaryButton, CustomButton, AppButtonGroup } from '../../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';

const style = {
    axOS: {
        marginTop: '45px'
    },
    axOS2: {
        marginTop: '152px'
    },
    ax2OS: {
        marginTop: '-18px'
    },
    ax2OS2: {
        marginTop: '0px'
    },
    ax2NS: { marginTop: '135px' },
    ax2Break: { marginBottom: '5px' },
    axBreakHeading: { textAlign: 'center' }
};

export const SingleAxle = (props) => {
    const { state, axNO } = props;
    const axle1 = state.tyreAxlesVM?.find((st) => st.axleNumber === axNO);
    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <Grid item xs={12} sm={12}>
                <h3>AXLE 1</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container xs={12} direction="row" justify="space-evenly">
                    <Grid item xs={3}>
                        <InputLabel shrink style={style.axOS}>
                            Depth*
                        </InputLabel>

                        <TextBox placeholder="mm" name="osTreadDepth" onChange={props.onChange()} value={axle1?.osTreadDepth} />
                        <InputLabel shrink style={style.axOS2}>
                            Depth*
                        </InputLabel>

                        <TextBox placeholder="mm" name="nsTreadDepth" onChange={props.onChange()} value={axle1?.nsTreadDepth} />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel shrink>Brakelining*</InputLabel>
                        <TextBox placeholder="%" name="osBrakeLineRemaining" onChange={props.onChange()} value={axle1?.osBrakeLineRemaining} />
                        <img
                            src={singleAxel}
                            height="250px"
                            width="100%"
                            style={{
                                marginTop: 5
                            }}
                        />
                        <InputLabel shrink>Brakelining*</InputLabel>
                        <TextBox placeholder="%" name="nsBrakeLineRemaining" onChange={props.onChange()} value={axle1?.nsBrakeLineRemaining} />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel shrink style={style.axOS}>
                            Pressure*
                        </InputLabel>
                        <TextBox placeholder="psi" name="osTyrePressure" onChange={props.onChange()} value={axle1?.osTyrePressure} />
                        <InputLabel shrink style={style.axOS2}>
                            Pressure*
                        </InputLabel>
                        <TextBox placeholder="psi" name="nsTyrePressure" onChange={props.onChange()} value={axle1?.nsTyrePressure} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const CustomAxle = (props) => {
    const { state, axNO } = props;
    let ax = state.tyreAxlesVM?.find((m) => m.axleNumber === axNO);
    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <h3>{props.axleTitle}</h3>
            <Grid item xs={12} sm={12}>
                <Grid container xs={12} direction="row" justify="space-evenly">
                    <Grid item xs={3}>
                        <InputLabel shrink style={style.ax2OS}>
                            Depth*
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="mm" name="osTreadDepth" value={ax?.osTreadDepth} />
                        <InputLabel shrink style={style.ax2OS2}>
                            {/* Depth* */}
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="mm" name="osFrontTreadDepth" value={ax?.osFrontTreadDepth} />
                        <InputLabel shrink style={style.ax2NS}>
                            Depth*
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="mm" name="nsFrontTreadDepth" value={ax?.nsFrontTreadDepth} />
                        <InputLabel shrink style={style.ax2OS2}>
                            {/* Depth* */}
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="mm" name="nsTreadDepth" value={ax?.nsTreadDepth} />
                    </Grid>
                    <Grid item xs={3}>
                        <img src={doubleAxelOne} height="100px" width="100%" />
                        <InputLabel shrink style={style.axBreakHeading}>
                            Brakelining*
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="%" name="osBrakeLineRemaining" value={ax?.osBrakeLineRemaining} />
                        <img src={doubleAxelTwo} height="45px" width="100%" />
                        <InputLabel shrink style={style.axBreakHeading}>
                            Brakelining*
                        </InputLabel>
                        <TextBox
                            onChange={props.onChange()}
                            placeholder="%"
                            name="nsBrakeLineRemaining"
                            style={style.ax2Break}
                            value={ax?.nsBrakeLineRemaining}
                        />
                        <img src={doubleAxelThree} height="100px" width="100%" />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel shrink style={style.ax2OS}>
                            Pressure*
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="psi" name="osTyrePressure" value={ax?.osTyrePressure} />
                        <InputLabel shrink style={style.ax2OS2}>
                            {/* Pressure* */}
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="psi" name="osFrontTyrePressure" value={ax?.osFrontTyrePressure} />
                        <InputLabel shrink style={style.ax2NS}>
                            Pressure*
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="psi" name="nsFrontTyrePressure" value={ax?.nsFrontTyrePressure} />
                        <InputLabel shrink style={style.ax2OS2}>
                            {/* Pressure* */}
                        </InputLabel>
                        <TextBox onChange={props.onChange()} placeholder="psi" name="nsTyrePressure" value={ax?.nsTyrePressure} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

// export const EditAxle = (props) => {
//     const { state } = props;
//     return (
//         <Grid item xs={12} sm={6} md={6} lg={3}>
//             <h3>AXLE{state.axleNumber}</h3>
//             <Grid item xs={12} sm={12}>
//                 <Grid container xs={12} direction="row" justify="space-evenly">
//                     <Grid item xs={3}>
//                         <InputLabel shrink style={style.ax2OS}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="osTreadDepth" disabled={true} value={state.osTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="osFrontTreadDepth" disabled={true} value={state.osFrontTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2NS}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="nsFrontTreadDepth" disabled={true} value={state.nsFrontTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="nsTreadDepth" disabled={true} value={state.nsTreadDepth || 'N/A'} />
//                     </Grid>
//                     <Grid item xs={3}>
//                         <img src={doubleAxelOne} height="100px" width="100%" />
//                         <InputLabel shrink style={style.axBreakHeading}>
//                             Brakelining*
//                         </InputLabel>
//                         <TextBox placeholder="%" name="osBrakeLineRemaining" disabled={true} value={state.osBrakeLineRemaining || 'N/A'} />
//                         <img src={doubleAxelTwo} height="45px" width="100%" />
//                         <InputLabel shrink style={style.axBreakHeading}>
//                             Brakelining*
//                         </InputLabel>
//                         <TextBox placeholder="%" name="nsBrakeLineRemaining" style={style.ax2Break} disabled={true} value={state.nsBrakeLineRemaining} />
//                         <img src={doubleAxelThree} height="100px" width="100%" />
//                     </Grid>
//                     <Grid item xs={3}>
//                         <InputLabel shrink style={style.ax2OS}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="osTyrePressure" disabled={true} value={state.osTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="osFrontTyrePressure" disabled={true} value={state.osFrontTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2NS}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="nsFrontTyrePressure" disabled={true} value={state.nsFrontTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="nsTyrePressure" disabled={true} value={state.nsTyrePressure || 'N/A'} />
//                     </Grid>
//                 </Grid>
//             </Grid>
//             {/* <FormHelperText  style={{ textAlign: 'center' }}></FormHelperText> */}
//         </Grid>
//     );
// };
