import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import './WeeklyFlashReport.scss';
import PublicIcon from '@material-ui/icons/Public';
import moment from 'moment';
import { useEffect } from 'react';
import { getWeeklyFlashReport } from '../../../../../Core/Service/ReportScreens-service';
import { useState } from 'react';

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'DOC', active: true },
    { name: measuredesc, active: true }
];

let currentYear = moment().year();

const WeeklyFlashReport = (props) => {
    const [state, setState] = useState({ tableData: {} });

    const pullTableData = async () => {
        let res = await getWeeklyFlashReport();
        setState((st) => ({ ...st, tableData: res.data[0] }));
    };

    useEffect(() => {
        pullTableData();
    }, []);

    return (
        <div className="weekly-flash-report-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            <Grid container spacing={2}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-aqua">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Truck Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{state.tableData.invoiceTruckMTD}</td>
                                            <td>{state.tableData.budgetInvoiceTruckMTD}</td>
                                            <td>{state.tableData.invoiceTruckYTD}</td>
                                            <td>{state.tableData.budgetInvoiceTruckYTD}</td>
                                            <td>{state.tableData.orderTruckMTD}</td>
                                            <td>{state.tableData.orderTruckYTD}</td>
                                            <td>{state.tableData.orderTruckWIP}</td>
                                            <td>{state.tableData.totalVolumeTruck}</td>
                                            <td>{state.tableData.budgetVolumeTruck}</td>
                                            <td>{state.tableData.budgetAchievedTruck}</td>
                                            <td>{state.tableData.wIPTruck}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-blue">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Van Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{state.tableData.invoiceVanMTD}</td>
                                            <td>{state.tableData.budgetInvoiceVanMTD}</td>
                                            <td>{state.tableData.invoiceVanYTD}</td>
                                            <td>{state.tableData.budgetInvoiceVanYTD}</td>
                                            <td>{state.tableData.orderVanMTD}</td>
                                            <td>{state.tableData.orderVanYTD}</td>
                                            <td>{state.tableData.orderVanWIP}</td>
                                            <td>{state.tableData.totalVolumeVan}</td>
                                            <td>{state.tableData.budgetVolumeVan}</td>
                                            <td>{state.tableData.budgetAchievedVan}</td>
                                            <td>{state.tableData.wIPVan}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-blue">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Maxus Van Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{state.tableData.invoiceMaxusMTD}</td>
                                            <td>{state.tableData.budgetInvoiceMaxusMTD}</td>
                                            <td>{state.tableData.invoiceMaxusYTD}</td>
                                            <td>{state.tableData.budgetInvoiceMaxusYTD}</td>
                                            <td>{state.tableData.orderMaxusMTD}</td>
                                            <td>{state.tableData.orderMaxusYTD}</td>
                                            <td>{state.tableData.orderMaxusWIP}</td>
                                            <td>{state.tableData.totalVolumeMaxus}</td>
                                            <td>{state.tableData.budgetVolumeMaxus}</td>
                                            <td>{state.tableData.budgetAchievedMaxus}</td>
                                            <td>{state.tableData.wIPMaxus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-black">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Used Sales</span>
                            </div>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="4">Used Truck Sales</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="2">MTD</th>
                                                            <th colspan="2">YTD</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>{state.tableData.invoiceUsedTruckMTD}</td>
                                                            <td>{state.tableData.budgetUsedTruckMTD}</td>
                                                            <td>{state.tableData.invoiceUsedTruckYTD}</td>
                                                            <td>{state.tableData.budgetUsedTruckYTD}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="4">Used Van Sales</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="2">MTD</th>
                                                            <th colspan="2">YTD</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>{state.tableData.invoiceUsedVanMTD}</td>
                                                            <td>{state.tableData.budgetInvoiceUsedVanMTD}</td>
                                                            <td>{state.tableData.invoiceUsedVanYTD}</td>
                                                            <td>{state.tableData.budgetInvoiceUsedVanYTD}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Used Truck Stocks</th>
                                                            <th>Current Wk</th>
                                                            <th>(Prior Month)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Used Stock Units</td>
                                                            <td>{state.tableData.truckUsedStockUnitsCurrentWk}</td>
                                                            <td>{state.tableData.truckUsedStockUnitsPriorMonth}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Used Stock Value</td>
                                                            <td>{state.tableData.truckUsedStockValueCurrentWk}</td>
                                                            <td>{state.tableData.truckUsedStockValuePriorMonth}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avg / vehicle</td>
                                                            <td>{state.tableData.truckAvgvehicleCurrentWk}</td>
                                                            <td>{state.tableData.truckAvgvehiclePriorMonth}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Used Van Stocks</th>
                                                            <th>Current Wk</th>
                                                            <th>(Prior Month)</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>Used Stock Units</td>
                                                            <td>{state.tableData.vanUsedStockUnitsCurrentWk}</td>
                                                            <td>{state.tableData.vanUsedStockUnitsPriorMonth}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Used Stock Value</td>
                                                            <td>{state.tableData.vanUsedStockValueCurrentWk}</td>
                                                            <td>{state.tableData.vanUsedStockValuePriorMonth}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avg / vehicle</td>
                                                            <td>{state.tableData.vanAvgvehicleCurrentWk}</td>
                                                            <td>{state.tableData.vanAvgvehiclePriorMonth}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-black">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Aftersales</span>
                            </div>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3">Parts MTD % of Flex Budget</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Trade</th>
                                                            <th>Workshop</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{state.tableData.aftersalesPartsMTDTrade}</td>
                                                            <td>{state.tableData.aftersalesPartsMTDWorkshop}</td>
                                                            <td>{state.tableData.aftersalesPartsMTDTotal}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">Service MTD % of Flex Budget</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Attended Hours</td>
                                                            <td>{state.tableData.aftersalesAttendedHours}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Booked Hours</td>
                                                            <td>{state.tableData.aftersalesServiceBookedHours}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3">Service Productivity</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th width="25%">% of Budget Achieved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Open'g WIP</td>
                                                            <td>{state.tableData.serviceProductivityOpenWIP}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hours Sold</td>
                                                            <td>{state.tableData.serviceProductivityHoursSold}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Current WIP</td>
                                                            <td>{state.tableData.serviceProductivityCurrentWIP}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Prod. Hours</td>
                                                            <td>{state.tableData.serviceProductivityProdHours}</td>
                                                            <td>{state.tableData.serviceProductivityBudgetAchieved}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-green">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Forecast</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th>Forecast</th>
                                            <th>Actual</th>
                                            <th>Budget</th>
                                            <th>% of Budget</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Parts Gross Margin v Budget</td>
                                            <td>{state.tableData.forecastActualPartsGrossMargin}</td>
                                            <td>{state.tableData.forecastBudgetPartsGrossMargin}</td>
                                            <td>{state.tableData.forecastpercBudgetPartsGrossMargin}</td>
                                        </tr>
                                        <tr>
                                            <td>Labour Revenue v Budget</td>
                                            <td>{state.tableData.forecastpercBudgetLabourRevenue}</td>
                                            <td>{state.tableData.forecastBudgetLabourRevenue}</td>
                                            <td>{state.tableData.forecastpercBudgetLabourRevenue}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-green">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Outstanding Cont.</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Current Wk</th>
                                            <th>(Prior Week)</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Impacting on cash flow</td>
                                            <td>{state.tableData.outstandingContCurrentWkImpactingcashflow}</td>
                                            <td>{state.tableData.outstandingContPriorWeekImpactingcashflow}</td>
                                        </tr>
                                        <tr>
                                            <td>Pending Registrations</td>
                                            <td>{state.tableData.outstandingContCurrentWkPendingRegistrations}</td>
                                            <td>{state.tableData.outstandingContPriorWeekPendingRegistrations}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-pink">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Vehicle Debtors</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Current Wk</th>
                                            <th>(Prior Week)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total Value</td>
                                            <td>{state.tableData.vehicleDebtorsCurrentWkTotalValue}</td>
                                            <td>{state.tableData.vehicleDebtorsPriorWeekTotalValue}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >30-days`}</td>
                                            <td>{state.tableData.vehicleDebtorsCurrentWk30daygreater}</td>
                                            <td>{state.tableData.vehicleDebtorsPriorWeek30daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >60-days`}</td>
                                            <td>{state.tableData.vehicleDebtorsCurrentWk60daygreater}</td>
                                            <td>{state.tableData.vehicleDebtorsPriorWeek60daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>Reg + Not Deferred</td>
                                            <td>{state.tableData.vehicleDebtorsCurrentWkRegNotDeferred}</td>
                                            <td>{state.tableData.vehicleDebtorsPriorWeekRegNotDeferred}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-bluegray">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Aged WIP</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colspan="2">Current Week</th>
                                            <th>(Prior Week)</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Current</td>
                                            <td>{state.tableData.agedWIPCurrentWkCurrent}</td>
                                            <td>{state.tableData.agedWIPCurrentWkCurrentperc}</td>
                                            <td>{state.tableData.agedWIPPriorWeekCurrent}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >30-days`}</td>
                                            <td>{state.tableData.agedWIPCurrentWk30daysgreater}</td>
                                            <td>{state.tableData.agedWIPCurrentWk30daysgreaterperc}</td>
                                            <td>{state.tableData.agedWIPPriorWeek30daysgreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >60-days`}</td>
                                            <td>{state.tableData.agedWIPCurrentWk60daysgreater}</td>
                                            <td>{state.tableData.agedWIPCurrentWk60daysgreaterperc}</td>
                                            <td>{state.tableData.agedWIPPriorWeek60daysgreater}</td>
                                        </tr>

                                        {/* <tr>
                                        <td>{`>90-days`}</td>
                                        <td>£10,882</td>
                                        <td>14.6%</td>
                                        <td></td>
                                    </tr>
                                    */}
                                        <tr>
                                            <td>Total</td>
                                            <td>{state.tableData.agedWIPCurrentWkTotal}</td>
                                            <td>{state.tableData.agedWIPCurrentWkTotalperc}</td>
                                            <td>{state.tableData.agedWIPPriorWeekTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-skype">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Cash Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colspan="2">Current Week</th>
                                            <th>(Prior Week)</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Current</td>
                                            <td>{state.tableData.cashSalesCurrentWkCurrent}</td>
                                            <td>{state.tableData.cashSalesCurrentWkCurrentperc}</td>
                                            <td>{state.tableData.cashSalesPriorWeekCurrent}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >30-days`}</td>

                                            <td>{state.tableData.cashSalesCurrentWk30daygreater}</td>
                                            <td>{state.tableData.cashSalesCurrentWk30daygreaterperc}</td>
                                            <td>{state.tableData.cashSalesPriorWeek30daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >60-days`}</td>
                                            <td>{state.tableData.cashSalesCurrentWk60daygreater}</td>
                                            <td>{state.tableData.cashSalesCurrentWk60daygreaterperc}</td>
                                            <td>{state.tableData.cashSalesPriorWeek60daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`>90-days`}</td>
                                            <td>{state.tableData.cashSalesCurrentWk90daygreater}</td>
                                            <td>{state.tableData.cashSalesCurrentWk90daygreaterperc}</td>
                                            <td>{state.tableData.cashSalesPriorWeek90daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{state.tableData.cashSalesCurrentWkTotal}</td>
                                            <td>{state.tableData.cashSalesCurrentWkTotalperc}</td>
                                            <td>{state.tableData.cashSalesPriorWeekTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-cyan">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Queries</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colspan="2">Count</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Current</td>
                                            <td>{state.tableData.queriesCurrentWkCurrent}</td>
                                            <td>{state.tableData.queriesCurrentWkCurrentperc}</td>
                                            <td>{state.tableData.queriesPriorWeekCurrent}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >30-days`}</td>
                                            <td>{state.tableData.queriesCurrentWk30daygreater}</td>
                                            <td>{state.tableData.queriesCurrentWk30daygreaterperc}</td>
                                            <td>{state.tableData.queriesPriorWeek30daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`Value >60-days`}</td>
                                            <td>{state.tableData.queriesCurrentWk60daygreater}</td>
                                            <td>{state.tableData.queriesCurrentWk60daygreaterperc}</td>
                                            <td>{state.tableData.queriesPriorWeek60daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>{`>90-days`}</td>
                                            <td>{state.tableData.queriesCurrentWk90daygreater}</td>
                                            <td>{state.tableData.queriesCurrentWk90daygreaterperc}</td>
                                            <td>{state.tableData.queriesPriorWeek90daygreater}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{state.tableData.queriesCurrentWkTotal}</td>
                                            <td>{state.tableData.queriesCurrentWkTotalperc}</td>
                                            <td>{state.tableData.queriesPriorWeekTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-gray-dark-v5">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>MTD Sales Gross Margin</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Budget</th>
                                            <th>Actual</th>
                                            <th>Variance</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>New truck</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewtruckBudget}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewtruckActual}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewtruckVariance}</td>
                                        </tr>
                                        <tr>
                                            <td>New Van</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewVanBudget}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewVanActual}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginNewVanVariance}</td>
                                        </tr>
                                        <tr>
                                            <td>Used Van</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedVanBudget}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedVanActual}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedVanVariance}</td>
                                        </tr>
                                        <tr>
                                            <td>Used Truck</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedTruckBudget}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedTruckActual}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginUsedTruckVariance}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{state.tableData.mtdSalesGrossMarginTotalBudget}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginTotalActual}</td>
                                            <td>{state.tableData.mtdSalesGrossMarginTotalVariance}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default WeeklyFlashReport;
