import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { grid } from 'gijgo';
import { AppStorage } from './../../Core/Service/storage-service';
import './dashboard.scss';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import { ExpBaseURL } from './../../Core/Service/http-calls';
var $ = window.$;

const JobDetails = (props) => {
    let dateDueInFulterValue = {
        1: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
        2: new Date().getFullYear(),
        3: new Date().getFullYear() - 1,
        4: ''
    };

    const token = AppStorage.getToken();
    useEffect(async () => {
        if (token) {
            pullJobDetailsAndUpdateState();
        } else {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token]);
    const pullJobDetailsAndUpdateState = async () => {
        const onSuccessFunc = function (response) {
            var obj = {
                records: response.data.rows,
                total: response.data.total
            };
            grid1.render(obj);
        };

        const redirectToDetails = (record) => {
            props.history.push(`/Job/${record.headerRecordID}/${record.workflowID}`);
        };

        const editButton = (vl, record) => {
            const spn = document.createElement('span');
            const lnk = (
                <IconButton size="small" onClick={() => redirectToDetails(record)}>
                    <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
            );
            ReactDOM.render(lnk, spn);
            return spn;
        };
        const GridID = props.match.params.id;
        let paramValues = dateDueInFulterValue[GridID];

        var grid1 = window.$('#grid').grid({
            primaryKey: 'Id',
            dataSource: {
                url: `${ExpBaseURL}/api/CustomerJobs?ShowRecord=${GridID}`,
                data: { dateDueIn: paramValues },
                headers: { Authorization: `Bearer ${token}` },
                success: onSuccessFunc
            },
            headerFilter: {
                type: 'onchange'
            },
            columns: [
                {
                    title: 'Action',
                    field: 'id',
                    renderer: editButton,
                    width: 30,
                    filterable: false
                },
                {
                    title: 'WIP Number',
                    field: 'wipNo',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Vehicle Reg',
                    field: 'registration',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Model',
                    field: 'makeModel',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Branch',
                    field: 'branchShortName',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Date Due In',
                    field: 'dateDueIn',
                    width: 90,
                    sortable: false,
                    type: 'date',
                    cssClass: 'text-center',
                    renderer: function (value, record) {
                        if (record.dateDueIn != null) {
                            return moment(record.dateDueIn).format('DD/MM/YYYY');
                        }
                        return '';
                    }
                },
                {
                    title: 'Date Due Out',
                    field: 'dateDueOut',
                    width: 90,
                    sortable: false,
                    type: 'date',
                    cssClass: 'text-center',
                    renderer: function (value, record) {
                        if (record.dateDueOut != null) {
                            return moment(record.dateDueOut).format('DD/MM/YYYY');
                        }
                        return '';
                    }
                }
            ].map((c) => {
                if (c.title !== 'Action') {
                    c.events = {
                        click: function (e) {
                            props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}`);
                        }
                    };
                }
                return {
                    ...c,
                    headerCssClass: 'gridHeader'
                };
            }),
            pager: { limit: 20 }
        });
        setTimeout(() => {
            var id = $(grid1).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);
            var colXPath = 'th [data-field="' + `${'dateDueIn'}` + '"]';
            var filterInput = $(filterRow).find(colXPath);
            $(filterInput).val(paramValues);
            grid1.data().params['dateDueIn'] = paramValues;
            grid1.reload();
        });
        grid1.on('dataBound', function (e, records, totalRecords) {});
    };
    return (
        <div className="customer-job-list">
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {props.match.params.name}
                </Typography>
            </Breadcrumbs>
            <Grid container className="common-gijgo-grid" style={{ margin: '10px auto' }}>
                <Grid item xs={12} sm={12}>
                    <table id="grid" style={{ width: '99%' }}></table>
                </Grid>
            </Grid>
        </div>
    );
};
export default JobDetails;
