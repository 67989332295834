import { get, post } from './http-calls'

export let getDocumentByName = async (docName, pageNo) => {
    let data = await get(`WorkflowDocument/${docName}${pageNo?`/${pageNo}`:''}`, { useAuthToken: true });
    return data;
}

export let uploadOcrDoc = async (jobId, ocrDocId, formData) => {
    let data = await post(`WorkflowDocument/${jobId}/${ocrDocId}`, formData, { useAuthToken: true });
    return data;
}

export const getOcrDocumentHistoryForJob = async (jobId, ocrDocId) => {
    let res = await get(`WorkflowDocument/history/${jobId}/${ocrDocId}`, { useAuthToken: true });
    return res;
}

export const deleteJobOcrDocument = async (jobId, ocrDocumentId, jobDocumentId, deletingCurrent) => {
    let res = await post(`WorkflowDocument/delete`, {
        jobId,
        ocrDocumentId,
        jobDocumentId,
        deletingCurrent
    }, { useAuthToken: true });
    return res;
}

export const setAsCurrentDocument = async (jobId, ocrDocumentId, jobDocumentId) => {
    let res = await post(`WorkflowDocument/MoveToSameJob`, {
        jobId,
        ocrDocumentId,
        jobDocumentId
    }, { useAuthToken: true });
    return res;
}

export const searchJobByText = async (jobId, searchText) => {
    let res = await get(`WorkflowDocument/SearchWip/${jobId}/${searchText}`, {useAuthToken: true})
    return res;
}

export const moveDocToOtherJob = async (jobId, nextJobId, ocrDocumentId, jobDocumentId, isCurrent ) => {
    let res = await post(`WorkflowDocument/MoveToOtherJob`, {
        jobId,
        nextJobId,
        ocrDocumentId,
        jobDocumentId,
        isCurrent
    }, { useAuthToken: true })
    return res;
}

export const mailDocument = async(data)=> {
    let res = await post(`WorkflowDocument/SendMail`, data, { useAuthToken: true })
    return res;
}