import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { SecondaryButton } from '../../../Core/FormInput/index';
import AddUpdateModal from './AddUpdateModal';
import { getStandardWriteUp } from '../../../Core/Service/techWriteUp-service';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteStandardWriteUpModal from './DeleteStandardWriteUp';
import DataGridComp from '../../../Core/Grid';

const StandardTechWriteUp = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        branches: [],
        showDel: false,
        StandardWriteUpID: null
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullStandardWriteUpAndUpdateState();
    }, []);
    const getClass = (params) => {
        if (!params.row.isActive) {
            return 'Deleted-record';
        }
    };
    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <Arrow value={params} />
        },
        { field: 'title', headerName: 'Title', flex: 1.5 },
        { field: 'complaint', headerName: 'Complaint', flex: 1.7 },
        {
            field: 'cause',
            headerName: 'Cause',
            flex: 3
        },
        {
            field: 'cure',
            headerName: 'Cure',
            flex: 4
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));
    const handleDeleteStandardWriteUp = (params) => {
        const StandardWriteUp_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                StandardWriteUpID: StandardWriteUp_id,
                StandardWriteUpDetails: params.value.row.title
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Standard Write Up" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Standard Write Up" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteStandardWriteUp(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const pullStandardWriteUpAndUpdateState = async () => {
        let result = await getStandardWriteUp('Y');
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.list
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                StandardWriteDataList: params.value.row
            };
            return nst;
        });
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                [props]: true,
                StandardWriteDataList: []
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No record available</div>
            </GridOverlay>
        );
    }
    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullStandardWriteUpAndUpdateState();
    };
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={() => handleAdd('show')} className="btnadd">
                    Add Standard Technician Write Up
                </SecondaryButton>
            </div>
            {state.show ? <AddUpdateModal onFormSubmit={modalClosed} StandardWriteDataList={state.StandardWriteDataList} /> : null}
            {state.showDel ? (
                <DeleteStandardWriteUpModal
                    StandardWriteUpID={state.StandardWriteUpID}
                    onFormSubmit={modalClosed}
                    StandardWriteUpDetails={state.StandardWriteUpDetails}
                />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Standard Technician Write-Up
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default StandardTechWriteUp;
