import * as Components from "./../../../../Components";
import { TypeOfAuth } from './../menu-auth-type';

export default [{
        label: "Home",
        path: "/",
        exact: true,
        component: Components.AuditorDashboard,
        authType: TypeOfAuth.Auth,
    },
    {
        label: 'Videos',
        path: '/Lists/Videos',
        exact: true,
        component: Components.Videos,
        authType: TypeOfAuth.Auth
    },
    {
        label: "Logout",
        path: "/logout",
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth,
    },
    {
        label: "Auditor",
        path: "/Auditor/:name/:id",
        exact: true,
        hidden: true,
        component: Components.AuditorJobDetails,
        authType: TypeOfAuth.Auth,
    },
    {
        label: "Workflow Job",
        hidden: true,
        exact: true,
        path: "/job/:jobId/:workflowId",
        component: Components.workflowJobDetails,
        authType: TypeOfAuth.Auth,
    }
];