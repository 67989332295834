import { createContext, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { getDefectByID, defectPostdata } from '../../../Core/Service/defectTech-service';
import { AppStorage } from '../../../Core/Service/storage-service';
import formatters from '../../../Core/Grid/inputFormatter';

export const DefectScreenContext = createContext();

export const DefectProvider = (props) => {
    const { hideModal, showToast, userRoles, hideTopNavAndMenu } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());

    const isInternal = userRoles.includes('internal');

    const navigateToDashboard = urlParams.get('navigate') === 'true';

    const fromStep = urlParams.get('fromstep') === 'true';

    const [inputList, setInputList] = useState([{ defectDescription: '', defectActionedCategoryID: '' }]);

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const signteamleaderCanvas = useRef({});

    const clearLeaderSign = () => {
        if (state.imgteamleadersign) {
            setState((st) => ({
                ...st,
                imgteamleadersign: null,
                teamleadersign: null
            }));
        } else {
            signteamleaderCanvas.current.clear();
        }
    };

    const checkFlag = (val) => {
        return val == 'true' ? true : false;
    };

    const [state, setState] = useState({
        createdUser: null,
        defectDateCreated: null,
        actionCreatedUser: null,
        defectDateActioned: null,
        defects: [],
        technicians: [],
        techniciansign: null,
        imgtechniciansign: null,
        imgteamleadersign: '',
        teamleadersign: '',
        wipNumber: '',
        registration: '',
        id: null,
        errors: {},
        showCircularLoader: true,
        isReadOnly: checkFlag(props.match.params?.isReadOnly),
        defectReportedCategories: [],
        defectActionedCategories: [],
        defectHeaderRecordID: props.match.params.jobId || null,
        headerRecords: {},
        userID: null,
        activeUser: null
    });

    const Id = props.match.params.jobId;

    const handleBtn = (e, id) => {
        let changedArray = [...inputList];
        changedArray[id].defectActionedCategoryID = e.defectActionedCategoryID;
        setInputList(changedArray);
    };

    const TechBtnClick = (userID) => {
        setState((st) => ({ ...st, userID: userID, activeUser: userID }));
    };

    const handleValidation = (val) => {
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (val === 'submit') {
            if (list[0]['defectDescription'] === '') {
                formIsValid = false;
                errors['defectDescription'] = 'Description cannot be empty';
            }
            if (!state.teamleadersign) {
                formIsValid = false;
                errors['teamleadersign'] = 'Leader signature cannot be empty';
            }
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const getDefect = async () => {
        let res = await getDefectByID(Id, state.userID);
        if (res.success) {
            const btnList = res.data?.defectReportedCategories;
            if (res.data) {
                setState((st) => ({
                    ...st,
                    technicians: res.data.technician,
                    defectReportedCategories: btnList,
                    defectActionedCategories: res.data?.defectActionedCategories,
                    showCircularLoader: false,
                    headerRecords: res.data.headerRecords[0],
                    imgtechniciansign: res.data?.defects[0]?.defectCreatedUserSignature || null,
                    activeUser: res.data?.defects[0]?.defectCreatedUserID,
                    techniciansign: res.data?.defects[0]?.defectCreatedUserSignature || null,
                    imgteamleadersign: res.data?.defects[0]?.defectActionedUserSignature || null,
                    teamleadersign: res.data?.defects[0]?.defectActionedUserSignature || null,
                    createdUser: res.data.defects[0]?.createdUser || null,
                    defectDateCreated: res.data.defects[0]?.defectDateCreated || null,
                    actionCreatedUser: res.data.defects[0]?.actionCreatedUser || null,
                    defectDateActioned: formatters.DateTimeFormatter(res.data.defects[0]?.defectDateActioned),
                    defects: res.data?.defects
                }));
            }
            setInputList(!res.data?.defects?.length ? [{ defectDescription: '', defectActionedCategoryID: '' }] : res.data?.defects);
        } else {
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    useEffect(() => {
        if (Id) {
            getDefect();
        }
    }, [state.userID]);

    const handleSubmit = async (value) => {
        const defectVM = inputList.map((m) => ({
            userSignature: state.teamleadersign,
            defectID: m.defectID,
            categoryID: m.defectActionedCategoryID,
            description: m.defectDescription
        }));

        let data = {
            defectHeaderRecordID: state.defectHeaderRecordID,
            mode: 'U',
            defectVM: defectVM
        };

        if (handleValidation(value)) {
            let res = await defectPostdata(data);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error || 'Failed to saved defect.  Please try again.');
            }
        }
    };

    const GetTeamleaderSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };
    const clearSig = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.techniciansign = null;
            return newSt;
        });
    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { defectDescription: '', defectActionedCategoryID: '' }]);
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signteamleaderCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, teamleadersign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };
    return (
        <DefectScreenContext.Provider
            value={{
                signteamleaderCanvas,
                state,
                fromStep,
                inputList,
                handleSubmit,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                TechBtnClick,
                handleBtn,
                hideModal,
                clearLeaderSign,
                GetTeamleaderSign,
                isInternal,
                useDefaultSig,
                clearSig
            }}
        >
            {props.children}
        </DefectScreenContext.Provider>
    );
};
