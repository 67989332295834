import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AppContext from '../../../../App/AppContext';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { getOcrDocumentField, getOcrDocuments } from './../../../../Core/Service/OcrDocuments-service';
import AddOCRDocFields from './AddUpdateModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { SecondaryButton } from '../../../../Core/FormInput/index';
import DataGridComp from '../../../../Core/Grid';

const OcrDocumentField = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        OCRDocFields: [],
        OCRName: ''
    });
    const { showToast } = useContext(AppContext);
    const ocrDocId = props.match.params.ocrDocId;

    useEffect(async () => {
        pullOcrDocumentFieldAndUpdateState();
    }, []);
    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                OCRDocFields: params.value.row
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update OCR Document Field" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                OCRDocFields: []
            };
        });
    };
    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'fieldLabel',
            headerName: 'Label',
            flex: 1
        },
        {
            field: 'isRequired',
            headerName: 'Is Required',
            flex: 1,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            field: 'columnName',
            headerName: 'Column Name',
            flex: 1
        }
    ];

    const pullOcrDocumentFieldAndUpdateState = async () => {
        let pros = [];
        pros.push(getOcrDocuments(), getOcrDocumentField(ocrDocId));
        let responses = await Promise.all(pros);

        const getOCRName = responses[0].data.rows
            .filter((m) => ocrDocId == m.id)
            .map((res) => {
                return res.name;
            });
        if (responses[0].success && responses[1].success) {
            let sortedRows = [];
            responses[1].data.map((row) => row.ocrDocumentId == ocrDocId && sortedRows.push(row));
            setState((st) => ({
                ...st,
                OCRName: getOCRName,
                rows: sortedRows
            }));
        }
        let resWithErr = responses.find((r) => !r.success);
        if (resWithErr) {
            showToast(resWithErr.message);
        }
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No OCR Document Field available</div>
            </GridOverlay>
        );
    }
    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false }));
        refreshGrid && pullOcrDocumentFieldAndUpdateState();
    };
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add OCR Document Field
                </SecondaryButton>
            </div>
            {state.show ? <AddOCRDocFields onFormSubmit={modalClosed} OCRDocFields={state.OCRDocFields} ocrDocId={ocrDocId} /> : null}

            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Link color="inherit" to={'/ocrdocuments'}>
                    OCR Documents
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    OCR Document Field
                </Typography>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {state.OCRName}
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default OcrDocumentField;
