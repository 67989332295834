import { Button, CircularProgress, DialogContent, Grid, Tooltip, FormLabel, IconButton, Box } from '@material-ui/core';
import { TextBox, CustomButton, SecondaryButton, AppButtonGroup } from '../../../Core/FormInput';
import React, { useEffect, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DefectProvider, DefectScreenContext } from './defectProvider';
import SignaturePad from 'react-signature-canvas';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

import './defect.scss';

const AddDefects = (props) => {
    const {
        TechBtnClick,
        fromStep,
        state,
        GetTeamleaderSign,
        handleSubmit,
        handleRemoveClick,
        handleInputChange,
        signteamleaderCanvas,
        inputList,
        handleBtn,
        clearLeaderSign,
        useDefaultSig,
        isInternal,
        clearSig
    } = useContext(DefectScreenContext);

    const { hideTopNavAndMenu } = useContext(AppContext);

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const history = useHistory();

    const [WindowWidths, WindowHeights] = useWindowSize();

    const isDisabled = () => {
        return isInternal ? state.isReadOnly : true;
    };
    const isReadonly = state.imgteamleadersign ? true : false;

    const showSubmit = state.defects.length > 0 ? true : false;
    return (
        <div className="defect-screen">
            <DialogContent className="mt-11">
                <Grid container spacing={2}>
                    <Grid item xs={9} sm={9} style={{ fontSize: 21, textAlign: 'left' }}>
                        Reg. No:&nbsp; <b> {state.headerRecords.registration} </b>&nbsp; WIP No:&nbsp; <b> {state.headerRecords.wipNumber}</b>
                    </Grid>
                    <Grid item sm={12}></Grid>
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            {/* {state.technicians.map((m) => (
                                <div className="tech-btn-group">
                                    <Button className={state.activeUser == m.userID ? 'active-btn' : 'all-btn'} onClick={() => TechBtnClick(m.userID)}>
                                        {m.technicianName}
                                    </Button>
                                </div>
                            ))} */}
                            {showSubmit && (
                                <>
                                    <Box width="100%" />
                                    {WindowWidths < 750 ? null : (
                                        <>
                                            <Grid item xs={12} sm={4} md={4} lg={6}>
                                                <FormLabel component="legend" className="mandatoryfields">
                                                    Defect
                                                </FormLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                                <FormLabel component="legend">Serviceable</FormLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                                <FormLabel component="legend">Action</FormLabel>
                                            </Grid>
                                        </>
                                    )}
                                    {inputList.map((x, i) => (
                                        <Grid item container spacing={2} style={{ background: i % 2 == 0 ? '#f7f7f7' : 'white', marginTop: 3 }}>
                                            <Grid item xs={12} sm={4} md={4} lg={6}>
                                                {WindowWidths < 750 && (
                                                    <FormLabel component="legend" className="mandatoryfields">
                                                        {`Defect-${i + 1}`}
                                                    </FormLabel>
                                                )}
                                                <TextBox
                                                    name="defectDescription"
                                                    autoFocus={true}
                                                    value={x.defectDescription}
                                                    disabled={true}
                                                    onChange={(e) => handleInputChange(e, i)}
                                                    multiline
                                                    rows={2}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={3} style={{ marginTop: 8 }}>
                                                <Grid container spacing={1}>
                                                    {WindowWidths < 750 && (
                                                        <Grid item xs={12}>
                                                            <FormLabel component="legend">{`Serviceable-${i + 1}`}</FormLabel>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        {state.defectReportedCategories && (
                                                            <AppButtonGroup
                                                                value={x.defectReportedCategoryID}
                                                                options={state.defectReportedCategories}
                                                                valuePropertyName="defectReportedCategoryID"
                                                                textPropertyName="defectReportedCategoryDescription"
                                                                disabled={true}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={3} style={{ float: 'right', marginTop: 8 }}>
                                                <Grid container spacing={1}>
                                                    {WindowWidths < 750 && (
                                                        <Grid item xs={12}>
                                                            <FormLabel component="legend">{`Action-${i + 1}`}</FormLabel>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        {state.defectActionedCategories && (
                                                            <AppButtonGroup
                                                                name="defectActionedCategoryID"
                                                                value={x.defectActionedCategoryID}
                                                                onChange={(e) => handleBtn(e, i)}
                                                                options={state.defectActionedCategories}
                                                                valuePropertyName="defectActionedCategoryID"
                                                                textPropertyName="defectActionedCategoryDescription"
                                                                disabled={isReadonly}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={12} lg={6} style={{ position: 'relative' }}>
                                        <CustomeSignPad
                                            onChange={GetTeamleaderSign}
                                            content={
                                                <FormLabel component="legend" className="mandatoryfields">
                                                    Signature
                                                    {(state.defectDateCreated || state.createdUser) && (
                                                        <span> ({`${state.createdUser || ' '} ${state.defectDateCreated}`})</span>
                                                    )}
                                                </FormLabel>
                                            }
                                            sign={state.imgtechniciansign}
                                            clearSig={clearSig}
                                            hideBtn={true}
                                            name="teamleadersign"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6} style={{ position: 'relative' }}>
                                        <CustomeSignPad
                                            onChange={GetTeamleaderSign}
                                            content={
                                                <FormLabel component="legend" className="mandatoryfields">
                                                    Leader Signature &nbsp;
                                                    {(state.defectDateActioned || state.actionCreatedUser) && (
                                                        <span> ({`${state.actionCreatedUser || ' '} ${state.defectDateActioned}`})</span>
                                                    )}
                                                </FormLabel>
                                            }
                                            sign={state.imgteamleadersign}
                                            clearSig={clearSig}
                                            name="teamleadersign"
                                        />
                                        <span className="mandatoryfields">{state.errors['teamleadersign']}</span>
                                    </Grid>
                                    {!isReadonly ? (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <SecondaryButton fullWidth onClick={() => handleSubmit('save')} disabled={isDisabled()}>
                                                    Save
                                                </SecondaryButton>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SecondaryButton fullWidth onClick={() => handleSubmit('submit')} disabled={isDisabled()}>
                                                    Sign-Off
                                                </SecondaryButton>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12} sm={12}>
                                            <SecondaryButton fullWidth onClick={() => history.goBack()}>
                                                Cancel
                                            </SecondaryButton>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {!showSubmit && (
                                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography color="secondary" variant="h2" component="h2">
                                            No defects reported by technician on this job
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
        </div>
    );
};

const Screen = (props) => {
    return (
        <DefectProvider {...props}>
            <AddDefects {...props} />
        </DefectProvider>
    );
};
export default Screen;
