import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import AppContext from '../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import { TextBox, SecondaryButton, SelectBox, AppSelect } from '../../../Core/FormInput';
import parse from 'html-react-parser';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { getQCSheet, addQCSheet, getQCDocuments, saveQCDocuments, deleteQCDocument } from './../../../Core/Service/qualityControl-service';
import moment from 'moment';
import { isBoolean, some } from 'lodash';
import './workflow-job.scss';
import MultiImageSliderModal from './DynamicForm/MultiImageSliderModal';
import { FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import { Backup } from '@material-ui/icons';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const QualityControlSheet = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast, userRoles, displayName } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');
    const urlParams = new URLSearchParams(props.location?.search?.toLowerCase());
    const navigateToDashboard = urlParams?.get('navigate') === 'true';
    const fromStep = urlParams?.get('fromstep') === 'true';
    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);
    const [WindowWidths, WindowHeights] = useWindowSize();

    const [state, setState] = useState({
        date: '',
        sign: null,
        imgsign: null,
        wipNumber: '',
        customer: '',
        registration: '',
        qcMessage: '',
        qcHeaderNotes: '',
        headerID: null,
        mileage: '',
        qcHeaderMOTJob: '',
        headerRecordID: '',
        qcItems: [],
        qcResultOptions: [],
        fields: {},
        errors: {},
        IssuerSignature: null,
        technicianList: [],
        qcHeaderSignatureName: displayName,
        qcHeaderPassed: true,
        imageList: []
    });

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.sign) {
            formIsValid = false;
            errors['sign'] = 'Signature cannot be empty';
        }
        if (!isBoolean(state.qcHeaderMOTJob)) {
            formIsValid = false;
            errors['qcHeaderMOTJob'] = 'Please select if it is an MOT job';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const Id = props?.match?.params?.jobId || props.jobID;
    useEffect(async () => {
        let result = await getQCSheet(Id);
        const updatedUser = result.data;
        if (updatedUser) {
            setState((st) => ({
                ...st,
                date: moment(updatedUser.dateDueIn).format('DD-MM-YYYY'),
                headerRecordID: updatedUser.headerRecordID,
                registration: updatedUser.registration,
                wipNumber: updatedUser.wipNumber,
                customer: updatedUser.customerName,
                IssuerSignature: updatedUser.qcHeaderSignature,
                sign: updatedUser.qcHeaderSignature,
                imgsign: updatedUser.qcHeaderSignature,
                mileage: updatedUser.qcHeaderMileage,
                qcHeaderMOTJob: updatedUser.qcHeaderMOTJob,
                qcItems: updatedUser.qcItems,
                qcResultOptions: updatedUser.qcResultOptions,
                qcMessage: updatedUser.qcMessage || '',
                headerID: updatedUser.qcHeaderID,
                qcHeaderNotes: updatedUser.qcHeaderNotes,
                technicianList: updatedUser.technicianList || [],
                qcHeaderSignatureName: updatedUser.qcHeaderSignatureName || displayName,
                qcHeaderPassed: updatedUser.qcHeaderPassed
            }));
        }
    }, []);

    const handleSubmit = async () => {
        if (handleValidation()) {
            state.qcItems.forEach((row) => {
                let findObj = state.qcResultOptions?.find((k) => +k?.qcResultOptionID == +row?.qcResultOptionID);
                if (findObj?.qcResultOptionDefectRequired && !row?.qcResultNotes) {
                    row.qcResultOptionDefectRequired = findObj?.qcResultOptionDefectRequired;
                } else if (findObj?.qcResultOptionDefectRequired && !row?.qcResultTechnicianID) {
                    row.qcResultOptionDefectRequired = findObj?.qcResultOptionDefectRequired;
                } else {
                    row.qcResultOptionDefectRequired = false;
                }
            });
            let checkFillAllFields = some(state.qcItems, (r) => r?.qcResultOptionDefectRequired);
            if (!checkFillAllFields) {
                const results = state.qcItems.map((row) => {
                    return {
                        qcResultOptionID: row.qcResultOptionID === null ? '3' : row.qcResultOptionID,
                        qcResultCheckID: row.qcResultCheckID === null ? row.qcItemID : row.qcResultCheckID,
                        qcResultNotes: row.qcResultNotes,
                        qcResultTechnicianID: row.qcResultTechnicianID
                    };
                });
                let res = await addQCSheet(
                    Id,
                    state.qcHeaderNotes,
                    state.sign,
                    state.mileage,
                    state.headerID,
                    results,
                    state.qcHeaderSignatureName,
                    state.qcHeaderMOTJob
                );
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                }
                showToast(res.message);
                if (res.success && fromStep) {
                    window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                }
            } else {
                showToast('Please Fill All Mandatory Fields!');
            }
        }
    };
    const handleClick = (val, id) => {
        setState((st) => {
            const nst = {
                ...st,
                qcItems: st.qcItems.map((el) =>
                    el.qcItemID == id
                        ? {
                              ...el,
                              ...val,
                              qcResultCheckID: id
                          }
                        : el
                )
            };
            return nst;
        });
    };

    const NoteChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = {
                ...st,
                qcItems: st.qcItems.map((el) => (el.qcItemID == name ? { ...el, qcResultNotes: value } : el))
            };
            return nst;
        });
    };

    const handelSelect = (id) => (e) => {
        const { name, value } = e.target;
        let changedArray = [...state.qcItems];
        let objIndex = state.qcItems.findIndex((x) => x.qcItemID === id);
        changedArray[objIndex][name] = value;

        setState((st) => ({ ...st, qcItems: changedArray }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            sign: null
        }));
    };

    const signChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const fieldChange = (e) => {
        const { name, value, type } = e.target;

        let fields = state.fields;
        fields[name] = value;

        setState((st) => {
            let state = { ...st };
            if (value === 'true' && type.toLowerCase() === 'radio') {
                state[name] = true;
            } else if (value === 'false' && type.toLowerCase() === 'radio') {
                state[name] = false;
            } else {
                state[name] = value;
            }
            state.fields = fields;
            return state;
        });
    };

    const getStripedStyle = (index) => {
        return {
            background: index % 2 ? 'rgb(151 133 133 / 6%)' : 'rgb(158 158 158 / 20%)'
        };
    };

    const docAction = (data) => {
        setState((st) => {
            return { ...st, showDoc: true, isLoading: true, selectedData: data };
        });
        downloadDocument(Id, data.qcItemID);
    };

    const downloadDocument = async (Id, qcItemID) => {
        setState((st) => {
            return { ...st, isLoading: true };
        });
        let res = await getQCDocuments(Id, qcItemID);
        if (res.success) {
            setState((st) => {
                return { ...st, isLoading: false, imageList: res.data || [] };
            });
        } else {
            setState((st) => {
                return { ...st, isLoading: false };
            });
        }
    };

    const uploadFiles = async (e, arrName) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(e.target.files[i].name, e.target.files[i]);
        }
        setState((st) => {
            return { ...st, isUploading: true };
        });

        let res = await saveQCDocuments(Id, state.selectedData?.qcItemID, formData);
        if (res.success) {
            downloadDocument(Id, state.selectedData?.qcItemID);
            showToast('File Uploaded successfully.');
            setState((st) => {
                return { ...st, isUploading: false };
            });
        }
    };

    const onImageRemove = async (data) => {
        let res = await deleteQCDocument(Id, data.id);
        if (res.success) {
            let tempData = state.imageList;
            let objIndex = tempData.findIndex((x) => x.id === data.id);
            if (objIndex > -1) {
                tempData.splice(objIndex, 1);
            }
            setState((st) => {
                return { ...st, imageList: tempData };
            });

            showToast(res.message);
        }
    };

    const mainContainerPaddingPercentage = WindowWidths < 500 ? '3%' : WindowWidths < 800 ? '6%' : WindowWidths < 1200 ? '1%' : '1%';

    return (
        <div
            style={{
                paddingLeft: mainContainerPaddingPercentage,
                paddingRight: mainContainerPaddingPercentage,
                paddingTop: '10px',
                paddingBottom: '30px',
                textAlign: 'left'
            }}
        >
            <Grid
                container
                spacing={2}
                style={{
                    backgroundColor: `${state.qcHeaderPassed == false ? 'red' : ''}`,
                    color: `${state.qcHeaderPassed == false ? 'white' : 'black'}`,
                    marginBottom: 7
                }}
            >
                <Grid item xs={6} sm={3}>
                    <FormLabel style={{ color: `${state.qcHeaderPassed == false ? 'white' : 'black'}` }}>WIP No</FormLabel>
                    <br></br>
                    <Typography variant="button" style={{ float: 'left' }}>
                        {state.wipNumber}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormLabel style={{ color: `${state.qcHeaderPassed == false ? 'white' : 'black'}` }} component="legend">
                        Registration Number
                    </FormLabel>
                    <br></br>
                    <Typography variant="button" style={{ float: 'left' }}>
                        {state.registration}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormLabel style={{ color: `${state.qcHeaderPassed == false ? 'white' : 'black'}` }}>Date</FormLabel>
                    <br></br>
                    <Typography variant="button" style={{ float: 'left' }}>
                        {state.date}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormLabel style={{ color: `${state.qcHeaderPassed == false ? 'white' : 'black'}` }} component="legend">
                        Customer
                    </FormLabel>
                    <br></br>
                    <Typography variant="button" style={{ float: 'left' }}>
                        {state.customer}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="qc_control">
                <Grid item xs={12} conatiner style={{ border: '1px solid #E8E8E8' }}>
                    {state.qcItems.map((m, i) => {
                        const findObj = state.qcResultOptions.find((k) => k.qcResultOptionID == m.qcResultOptionID);
                        const newList = state.qcResultOptions
                            .filter((item) => item.qcResultOptionListID == m.qcItemResultListID)
                            .map((p) => ({
                                ...p,
                                foregroundColour: `${p['qcResultOptionForegroundColour']}`,
                                backgroundColour: `${p['qcResultOptionBackgroundColour']}`
                            }));
                        return (
                            <Grid conatiner item xs={12} sm={12} style={{ padding: '0px 8px 10px 8px', ...getStripedStyle(i) }}>
                                <Grid container spacing={1} justifyContent="space-between">
                                    <Grid container spacing={1} item sm={12} xs={6} md={4} lg={3}>
                                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormLabel component="legend" className="btn_add" style={{ textAlign: 'left' }}>
                                                {m.qcItemTitle}
                                            </FormLabel>
                                        </Grid>
                                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid item sm={6} xs={6} lg={4} container style={{ textAlign: 'left' }}>
                                                <Grid item xs={12}>
                                                    <AppSelect
                                                        displayType="BtnGroup"
                                                        name="qcResultOptionID"
                                                        value={`${m.qcResultOptionID}`}
                                                        options={newList}
                                                        onChange={(val) => handleClick(val, m.qcItemID)}
                                                        valuePropertyName="qcResultOptionID"
                                                        textPropertyName="qcResultOptionDescription"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={6} xs={6} lg={8}>
                                                <SelectBox
                                                    // style={{ marginTop: 8 }}
                                                    List={state.technicianList}
                                                    inputLabel="Technician"
                                                    name="qcResultTechnicianID"
                                                    value={m.qcResultTechnicianID}
                                                    onChange={handelSelect(m.qcItemID)}
                                                    className={findObj?.qcResultOptionDefectRequired ? `mandatoryfields` : ''}
                                                    error={!m.qcResultTechnicianID && findObj?.qcResultOptionDefectRequired}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item sm={12} xs={12} md={8} lg={9} alignItems="center">
                                        <Grid item xs className="multilineText">
                                            <TextBox
                                                className={findObj?.qcResultOptionDefectRequired ? `mandatoryfields btn_add  ` : 'btn_add  '}
                                                multiline
                                                error={!m.qcResultNotes && findObj?.qcResultOptionDefectRequired}
                                                rows={2}
                                                name={m.qcItemID}
                                                placeholder="Defect"
                                                value={m.qcResultNotes}
                                                onChange={NoteChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <label title="Upload Documents">
                                                <IconButton color={'secondary'} component="span" onClick={() => docAction(m)} style={{ padding: '0px 3px' }}>
                                                    <Backup />
                                                </IconButton>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <Typography style={{ color: 'red' }}>{parse(state.qcMessage)}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={12} md={6} lg={6}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <FormLabel component="legend">MOT Job</FormLabel>
                        <br />
                        <RadioGroup
                            style={{ display: 'block' }}
                            aria-label="qcHeaderMOTJob"
                            name="qcHeaderMOTJob"
                            value={state.qcHeaderMOTJob}
                            onChange={fieldChange}
                        >
                            <FormControlLabel checked={state.qcHeaderMOTJob === true} value={true} control={<Radio />} label="Yes" />

                            <FormControlLabel checked={state.qcHeaderMOTJob === false} value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                        <span className="mandatoryfields">{state.errors['qcHeaderMOTJob']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormLabel component="legend">Mileage</FormLabel>
                        <TextBox value={state.mileage} type="number" name="mileage" onChange={fieldChange} />
                    </Grid>

                    <Grid item xs={12} sm={12} className="multilineActionText">
                        <FormLabel component="legend" style={{ marginTop: 28 }}>
                            What action was taken to remedy the above found error(s)
                        </FormLabel>
                        <TextBox name="qcHeaderNotes" value={state.qcHeaderNotes} onChange={fieldChange} multiline rows={6} />
                    </Grid>
                </Grid>

                <Grid container spacing={1} item xs={12} sm={12} md={6} lg={6} alignContent="space-between">
                    <Grid item xs={12}>
                        <FormLabel component="legend">Q.C carried out by:</FormLabel>
                        {/* <Typography style={{ float: 'left' }}>Q.C carried out by:</Typography>{' '} */}
                        <TextBox value={state.qcHeaderSignatureName} name="qcHeaderSignatureName" onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomeSignPad
                            onChange={signChange}
                            content="Signature"
                            sign={state.IssuerSignature}
                            clearSig={clearSig}
                            name="sign"
                            hideBtn={false}
                        />
                        <span className="mandatoryfields">{state.errors['sign']}</span>
                    </Grid>
                </Grid>
            </Grid>
            <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={isAuditor}>
                Submit
            </SecondaryButton>
            {state.showDoc && (
                <MultiImageSliderModal
                    imageList={state.imageList}
                    selectedFieldData={state.selectedFieldData}
                    onImageUpload={uploadFiles}
                    onImageRemove={onImageRemove}
                    isDelete={true}
                    isLoading={state.isLoading}
                    isUploading={state.isUploading}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showDoc: false,
                            imageList: []
                        }))
                    }
                />
            )}
        </div>
    );
};
export default QualityControlSheet;
