import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, TextBox, DialogContents, DialogTitles, Dialogs, DialogsActions, DefaultButton } from '../../../Core/FormInput/index';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import '..//User Management/UserManagement.css';
import { AddStandardWriteUp, getStandardWriteUpById, AddStandardWriteUpUpdate } from '../../../Core/Service/techWriteUp-service';
import '../../technician/technician-app/TechnicianStyle.scss';
import SpeedDials from '../../../Core/Controls/SpeedDial';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import DialogComp from '../../../Core/Modal/dialogModal';

const AddUpdateModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const StandardWriteData = props.StandardWriteDataList;
    const [state, setState] = useState({
        complaint: '',
        cause: '',
        cure: '',
        Title: '',
        errors: {},
        StandardWriteUpID: null,
        isActive: true,
        languages: [],
        selectedLanguageCode: 'en',
        translationExistsY: [],
        translationExistsN: [],
        selectedData: '',
        selectedLanguages: []
    });

    useEffect(() => {
        if (StandardWriteData && StandardWriteData.id) {
            pullData();
        }
    }, []);

    const pullData = async () => {
        let result = await getStandardWriteUpById(StandardWriteData.id);
        let selectedData = result.data.standardWriteup.find((p) => state.selectedLanguageCode == p.languageCode);

        setState((st) => ({
            ...st,
            languages: result.data.languages,
            standardWriteupList: result.data.standardWriteup,
            StandardWriteUpID: StandardWriteData.id,
            TranslatedID: selectedData.id,
            complaint: selectedData.complaint,
            cause: selectedData.cause,
            cure: selectedData.cure,
            Title: selectedData.title,
            isActive: selectedData.isActive,
            selectedLanguages: []
        }));
    };

    useEffect(() => {
        setState((st) => ({
            ...st,
            translationExistsY: state.languages.filter((o) => o.translationExists == 'Y'),
            translationExistsN: state.languages
                .filter((o) => o.translationExists == 'N')
                .map((p) => {
                    return {
                        icon: `${p.languageCode.toUpperCase()}`,
                        name: `${p.languageDescription}`,
                        action: () => speedDialActionHandler(p)
                    };
                })
        }));
    }, [state.languages]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const speedDialActionHandler = (value) => {
        let tempSelectedLanguage = [...state.selectedLanguages];
        if (tempSelectedLanguage.indexOf(value.languageCode) === -1) {
            tempSelectedLanguage.push(value.languageCode);
        }
        setState((st) => {
            const nst = { ...st };
            nst.showConfirmModal = true;
            nst.modalLaguage = value.languageDescription;
            nst.selectedLanguages = tempSelectedLanguage;
            nst.TranslatedID = null;
            return nst;
        });
    };

    const speedDialActionSubmitHandler = () => {
        handelSubmit(true);
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        // if (!state.Title) {
        //     formIsValid = false;
        //     errors['Title'] = 'Title cannot be empty';
        // }
        if (!state.complaint) {
            formIsValid = false;
            errors['complaint'] = 'Complaint cannot be empty';
        }
        if (!state.cure) {
            formIsValid = false;
            errors['cure'] = 'Cure cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const handelSubmit = async (flag) => {
        let tempLang = [];
        let res = '';
        if (flag && state.StandardWriteUpID) {
            tempLang = [...state.selectedLanguages];
        } else {
            tempLang.push(state.selectedLanguageCode);
        }
        if (handleValidation()) {
            let data = {
                Id: state.StandardWriteUpID,
                Title: state.Title,
                Complaint: state.complaint,
                Cause: state.cause,
                Cure: state.cure,
                isActive: state.isActive,
                Languages: tempLang,
                TranslatedID: state.TranslatedID || ''
            };
            if (state.StandardWriteUpID) {
                res = await AddStandardWriteUpUpdate(data);
            } else {
                res = await AddStandardWriteUp(data);
            }
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                !flag && props.onFormSubmit(true);
                flag && pullData();
                flag && confirmationModalClose();
            }
            showToast(res.message);
        }
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        if (StandardWriteData.id) {
            let selectedId = state.standardWriteupList.findIndex((p) => state.selectedLanguageCode == p.languageCode);

            setState((st) => {
                const nst = { ...st, [name]: value };
                nst.standardWriteupList[selectedId][name] = value;
                return nst;
            });
        } else {
            setState((st) => {
                const nst = { ...st, [name]: value };
                return nst;
            });
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const confirmationModalClose = () => {
        setState((st) => ({ ...st, showConfirmModal: false }));
    };

    const TechBtnClick = (id) => {
        if (state.selectedLanguageCode != id) {
            let selectedData = state.standardWriteupList.find((p) => id == p.languageCode);

            setState((st) => ({
                ...st,
                selectedLanguageCode: id,
                TranslatedID: selectedData.id,
                complaint: selectedData.complaint,
                cause: selectedData.cause,
                cure: selectedData.cure,
                Title: selectedData.title,
                isActive: selectedData.isActive
            }));
        }
    };

    return (
        <>
            <Dialogs open={true} onClose={handleClose} maxWidth="md">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        {state.StandardWriteUpID ? (
                            <DialogTitles>Update Standard Technician Write-Up</DialogTitles>
                        ) : (
                            <DialogTitles>Add Standard Technician Write-Up</DialogTitles>
                        )}
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContents className="hiddenScroll">
                    {state.StandardWriteUpID && (
                        <Grid container>
                            <Grid container item xs={11}>
                                {state.translationExistsY.map((m) => (
                                    <Grid item className="tech-btn-group">
                                        <Button
                                            className={state.selectedLanguageCode == m.languageCode ? 'active-btn' : 'all-btn'}
                                            onClick={() => TechBtnClick(m.languageCode)}
                                            disabled={m.isDisabled}
                                        >
                                            {m.languageDescription}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>

                            {state.translationExistsN.length > 0 && (
                                <Grid xs={1} item>
                                    <SpeedDials actions={state.translationExistsN} direction={'left'} bottom={-40} right={0} />
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={12}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Title
                            </FormLabel>
                            <TextBox autoFocus={true} name="Title" onChange={fieldChange} value={state.Title} />
                            <span className="mandatoryfields">{state.errors['Title']}</span>
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Complaint
                            </FormLabel>
                            <TextBox name="complaint" onChange={fieldChange} value={state.complaint} multiline rows={2} />
                            <span className="mandatoryfields">{state.errors['complaint']}</span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Cause</FormLabel>
                            <TextBox name="cause" onChange={fieldChange} value={state.cause} multiline rows={2} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Cure
                            </FormLabel>
                            <TextBox name="cure" value={state.cure} onChange={fieldChange} multiline rows={2} />
                            <span className="mandatoryfields">{state.errors['cure']}</span>
                        </Grid>
                        {state.StandardWriteUpID && (
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2} sm={2}>
                                        <FormLabel component="legend" className="btn_add">
                                            Is Active?
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <DefaultButton
                                            className={state.isActive ? 'btnActive' : 'btninActive'}
                                            value={true}
                                            name="isActive"
                                            onClick={() => handleClick('isActive', true)}
                                        >
                                            Yes
                                        </DefaultButton>
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <DefaultButton
                                            className={state.isActive ? 'btninActive' : 'btnActiveNo'}
                                            value={false}
                                            name="isActive"
                                            onClick={() => handleClick('isActive', false)}
                                        >
                                            No
                                        </DefaultButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContents>
                <DialogsActions className="dialogActions">
                    <SecondaryButton className="Submitbtn" onClick={() => handelSubmit(false)}>
                        Submit
                    </SecondaryButton>
                </DialogsActions>
            </Dialogs>
            {state.showConfirmModal ? (
                <DialogComp title="Confirmation Modal" onClose={confirmationModalClose} maxWidth="sm">
                    <ConfirmationModal
                        message={`Are you sure want add transaltion for ${state.modalLaguage} language`}
                        handleSubmit={speedDialActionSubmitHandler}
                        handleCancel={confirmationModalClose}
                    />
                </DialogComp>
            ) : null}
        </>
    );
};

export default AddUpdateModal;
