import { Checkbox, FormControlLabel, Grid, InputLabel } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { editInvoiceCustomer, getInvoiceCustomerById } from '../../../../Core/Service/InvoiceScreen-service';
import { isNull } from 'lodash';
import { emailValidation } from '../../../../Core/Service/StaticService';

const InvoiceCustomersEditScreen = (props) => {
    const [state, setState] = useState({ errors: {}, custData: {}, invoiceEmail: '', sendInvoiceByEmail: false });
    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        let res = await getInvoiceCustomerById(props.customerId);
        setState((st) => {
            const nst = { ...st };
            nst.custData = res.data.table[0];
            nst.sendInvoiceByEmail = res.data.table[0].sendInvoiceByEmail;
            nst.invoiceEmail = res.data.table[0].invoiceEmail;
            return nst;
        });
    }, []);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };

            newSt[name] = value;

            return newSt;
        });
    };

    const validations = () => {
        const { invoiceEmail } = state;
        let formIsValid = true;
        let errors = {};

        if (!emailValidation(invoiceEmail)) {
            errors.invoiceEmail = 'Invalid Email Id';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let data = { id: props.customerId, invoiceEmail: state.invoiceEmail, sendInvoiceByEmail: state.sendInvoiceByEmail };

            let res = await editInvoiceCustomer(data);
            if (res.success) {
                showToast(`Customer Updated Successfully`);
                props.onClose(true);
            } else {
                console.error(res.message);
            }
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        {`Name`}
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        {`${state.custData.customerSName}(${state.custData.customerCode})`}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        Address
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        {`${state.custData.addressLine1} ${state.custData.addressLine2} ${state.custData.addressLine3} ${state.custData.addressLine4} ${state.custData.addressLine5}`}
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12} className="mandatoryfields" style={{ paddingTop: '20px' }}>
                <FormControlLabel
                    control={<Checkbox checked={state.sendInvoiceByEmail} onChange={handleCheckbox} name="sendInvoiceByEmail" />}
                    label="Send Invoice by email?"
                />
            </Grid>
            <Grid item xs={12} className="mandatoryfields">
                <InputLabel shrink>
                    Invoice Email <span style={{ color: 'red' }}>{`(Please use comma to separate multiple email addresses)`}</span>
                </InputLabel>

                <TextBox autoFocus name="invoiceEmail" onChange={fieldChange} value={state.invoiceEmail} />
                <span className="mandatoryfields">{state.errors['invoiceEmail']}</span>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default InvoiceCustomersEditScreen;
