import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useEffect, useContext, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import { getWorkflowDashBoard } from '../../../Core/Service/workflowJobs-service';
import { InputLabel, Tooltip, CircularProgress } from '@material-ui/core';

import '../../customer/dashboard.scss';

const color = {};

export default function WorkflowDash(props) {
    const [state, setState] = useState({
        cards: [],
        returnReasons: [],
        showLoader: true
    });
    const { showToast } = useContext(AppContext);
    useEffect(async () => {
        // let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: '' }));

        let res = await getWorkflowDashBoard(props.workFlowId);
        if (res.success) {
            setState((st) => ({ ...st, cards: res.data.details, showLoader: false }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    }, [props.workFlowId]);

    return (
        <div className="customer-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justify="center">
                        <CircularProgress />
                    </Grid>
                ) : state.cards.length > 0 ? (
                    <Grid container spacing={4}>
                        {state.cards.map((option) => (
                            <Grid item key={option.id} xs={6} sm={6} md={6} lg={4}>
                                <Link
                                    style={{ textDecoration: 'none', color: 'black' }}
                                    to={{
                                        pathname: '/dashgrid',
                                        query: {
                                            workFlowId: props.workFlowId,
                                            returnReason: `${option.returnReasonID == 0 ? '' : option.returnReason}`,
                                            returnReasonID: option.returnReasonID
                                        }
                                    }}
                                >
                                    <Card
                                        className="card"
                                        style={{
                                            backgroundColor: `${option.returnReasonColourCode}`
                                        }}
                                    >
                                        <CardContent className="cardContent">
                                            <Typography variant="h5" style={{ color: `${option.returnReasonTextColourCode}` }}>
                                                {option.returnReason}
                                            </Typography>
                                            <Typography variant="h5" style={{ color: `${option.returnReasonTextColourCode}` }}>
                                                {option.total}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    'No records Found'
                )}
            </Container>
        </div>
    );
}
