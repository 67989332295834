import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SecondaryButton } from "../../../../Core/FormInput/index";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import AppContext from "../../../../App/AppContext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { getByWorkflowIdAndHeaderId } from "../../../../Core/Service/returnReason-service";
import AddReturnReasonModal from "./AddReturnReasonModal";
import { getWorkflows } from "../../../../Core/Service/workflow-service";
import { makeStyles } from '@material-ui/core/styles';

const useStyles =(props)=> makeStyles((thems) => {
    return ({
        ...props
    })
});

const ReturnReason = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        ReturnReasonId: null,
        ReturnReasonDetails: [],
        workflowList: [],
    });
    const { showToast } = useContext(AppContext);
    const WorkflowID = props.match.params.workflowId;
    useEffect(async () => {
        pullReturnReasonAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        const ReturnReason_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                ReturnReasonId: ReturnReason_id,
                ReturnReasonDetails: params.value.row,
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Return Reason" aria-label="add">
                    <IconButton
                        size="small"
                        onClick={() => handleUpdate(params)}
                    >
                        <EditOutlinedIcon style={{color:params.value.row.returnReasonTextColourCode}} fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    var colorCodeObj = {};
    state.rows.forEach(c => {
        colorCodeObj[[c.id]] = {
            background: c.returnReasonColourCode,
            color: c.returnReasonTextColourCode
        };
    })
    const classes = useStyles(colorCodeObj)();

    const getClass = (params) => {
        if (params.row.returnReasonColourCode) {
            return classes[params.row.id]
        }
    };

    const column_new = [
        {
            field: "id",
            headerName: "ID",
            width: 130,
            sortable: false,
            hide: true,
        },
        {
            field: "",
            headerName: "Action",
            sortable: false,
            width: 120,

            renderCell: (params) => <Arrow value={params} />,
        },
        {
            field: "returnReasonDescription",
            headerName: "Reason",
            flex: 1,
        },
        {
            field: "returnReasonNextWorkflowID",
            headerName: "Next Workflow",
            flex: 1,
            valueGetter: (params) =>
                state.workflowList
                    .filter((m) => params.value == m.id)
                    .map((res) => {
                        return res.workflowName;
                    }),
        },
        {
            field: "uniqIdentifier",
            headerName: "Unique Identifier",
            flex: 1,
        },
        {
            field: "returnReasonColourCode",
            headerName: "Background Colour",
            flex: 1,
        },
        {
            field: "returnReasonTextColourCode",
            headerName: "Text Colour",
            flex: 1,
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));

    const pullReturnReasonAndUpdateState = async () => {
        let result = await getByWorkflowIdAndHeaderId(WorkflowID);
        let result1 = await getWorkflows();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.returnReasons.map((el) => ({
                    ...el,
                    id: el.returnReasonID,
                })),
                show: false,
                showDel: false,
                workflowList: result1.data,
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                ReturnReasonId: null,
                ReturnReasonDetails: null,
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Return Reason data available</div>
            </GridOverlay>
        );
    }
    return (
        <div>
            {state.show ? (
                <AddReturnReasonModal
                    WorkflowID={WorkflowID}
                    onFormSubmit={pullReturnReasonAndUpdateState}
                    ReturnReasonDetails={state.ReturnReasonDetails}
                />
            ) : null}
            <SecondaryButton
                variant="contained"
                color="secondary"
                onClick={handleAdd}
                className="btnadd"
            >
                Add Return Reason
            </SecondaryButton>
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Link color="inherit" to={"/workflow"}>
                    Workflow
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    Return Reasons
                </Typography>
            </Breadcrumbs>
            <Grid container className="roots">
                <Grid item xs={12} sm={12}>
                    <div
                        style={{
                            height: "calc(100vh - 200px)",
                            width: "99%",
                            margin: "10px",
                            marginTop: '-14px'
                        }}
                    >
                        <DataGrid
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            rows={state.rows}
                            headerHeight={30}
                            rowHeight={30}
                            hideFooter={true}
                            columns={column_new}
                            pageSize={100}
                            disableColumnMenu={true}
                            showColumnRightBorder={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default ReturnReason;
