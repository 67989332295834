import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';

export default [
    {
        label: 'Technician App',
        path: '/',
        exact: true,
        component: Components.technicianApp,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Videos',
        path: '/Lists/Videos',
        exact: true,
        component: Components.Videos,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Workflow Job',
        hidden: true,
        exact: true,
        path: '/job/:jobId/:workflowId/:technicianJobId?/:isTechReadonly?',
        component: Components.workflowJobDetails,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    }
];
