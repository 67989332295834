import React, { useEffect, useState, useContext } from 'react';
import { IconButton, MenuItem, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import DataGridComp from '../../../Core/Grid';
import AppContext from '../../../App/AppContext';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { getStandardWriteUp } from './../../../Core/Service/techWriteUp-service';
import { DialogContents, DialogTitles, Dialogs, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const DisplayGrid = (props) => {
    const [state, setState] = useState({
        rows: [],
        selectedData: [],
        langCode: 'en',
        enLanguages: []
    });
    const { hideModal, showToast, getUserPreference } = useContext(AppContext);
    const [windowWidths, windowHeights] = useWindowSize();

    useEffect(async () => {
        await pullStandardAndUpdateState();
    }, [state.langCode]);

    useEffect(async () => {
        let data = getUserPreference();
        setState((st) => {
            return { ...st, langCode: data.details[0].languageCode };
        });
    }, []);

    const getClass = (params) => {
        if (params.row.id === state.selectedData.id) {
            return 'days-past-due-date-in-40';
        }
    };

    const RowData = (params) => {
        let data = params.value.row;
        return (
            <div>
                <p>
                    <span style={{ color: 'red' }}>Complaint:</span> {`${data.complaint}`}, <span style={{ color: 'red' }}>Cause:</span> {`${data.cause}`},{' '}
                    <span style={{ color: 'red' }}>Cure:</span> {`${data.cure}`}
                </p>
            </div>
        );
    };

    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },

        { field: 'standard', headerName: 'Standard Write Up', renderCell: (params) => <RowData value={params} />, flex: 1 }
        // { field: 'complaint', headerName: 'Complaint', flex: 1.7 },
        // {
        //     field: 'cause',
        //     headerName: 'Cause',
        //     flex: 3
        // },
        // {
        //     field: 'cure',
        //     headerName: 'Cure',
        //     flex: 4
        // }
    ].map((c) => ({ ...c, cellClassName: getClass }));

    const pullStandardAndUpdateState = async () => {
        let result = await getStandardWriteUp('N', state.langCode);
        if (result.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.rows = result.data?.list;
                newSt.languages = result.data?.languages;
                newSt.show = false;
                newSt.showDel = false;
                if (state.langCode === 'en') {
                    newSt.enLanguages = result.data?.list;
                }
                return newSt;
            });
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleClose = () => {
        props.onFormSubmit();
        hideModal();
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No records available</div>
            </GridOverlay>
        );
    }
    const onSubmitStandard = (e) => {
        if (state.langCode === 'en') {
            props.onStandardWriteup(state.selectedData);
        } else {
            let findEnglish = state.enLanguages.find((a) => a.id == state.selectedData.id);
            props.onStandardWriteup(findEnglish);
        }
        props.onFormSubmit();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const ButtonComp = () => {
        return (
            <Grid container spacing={1} xs={12} justify="space-between">
                <Grid item xs={12} sm={4} md={2}>
                    <SecondaryButton
                        style={{
                            // position: 'fixed',
                            borderRadius: '20px',
                            // margin: -8
                            marginTop: '15px'
                        }}
                        onClick={onSubmitStandard}
                    >
                        Select
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">Language</FormLabel>
                    <TextBox select value={state.langCode} name="langCode" onChange={fieldChange} rows={2}>
                        {state.languages?.length > 0 &&
                            state.languages.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextBox>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <Dialogs open={true} onClose={handleClose} maxWidth="lg" fullWidth>
                <Box
                    display="flex"
                    alignItems="center"
                    // className="setStandardWidth"
                    style={{ height: '40px' }}
                >
                    <Box flexGrow={1}>
                        <DialogTitles style={{ padding: '0px 0px 0px 22px' }}>Select Standard Write Up</DialogTitles>
                    </Box>
                    <Box style={{ marginRight: '0px' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContents
                    //  className="setStandardWidth removeScroll"
                    style={{ height: '68vh' }}
                >
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} sm={12}>
                            <SecondaryButton className="addbtn" onClick={onSubmitStandard}>
                                Select
                            </SecondaryButton>
                        </Grid> */}
                        <Grid xs={12} sm={12}>
                            <div
                                style={{
                                    height: 'calc(100vh - 330px)'
                                    // width: `${windowWidths < 800 ? '700px' : ''}`

                                    // marginTop: '-25px'
                                }}
                            >
                                <DataGridComp
                                    columns={column_new}
                                    rows={state.rows}
                                    headerHeight={40}
                                    isRowHovered={true}
                                    enableSearch={true}
                                    disableColumnMenu={true}
                                    offset={360}
                                    showFooter={true}
                                    // onRowSelected={(Params) => {
                                    //     setState((st) => ({
                                    //         ...st,
                                    //         selectedData: Params.data
                                    //     }));
                                    // }}
                                    onRowDoubleClick={(params, event) => {
                                        setState((st) => ({
                                            ...st,
                                            selectedData: params.row
                                        }));
                                        onSubmitStandard();
                                        if (!event.ctrlKey) {
                                            event.defaultMuiPrevented = true;
                                        }
                                    }}
                                    onRowClick={(Params, e) => {
                                        setState((st) => ({
                                            ...st,
                                            selectedData: Params.row
                                        }));
                                    }}
                                    buttonComp={<ButtonComp />}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContents>
            </Dialogs>
        </div>
    );
};
export default DisplayGrid;
