import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const JobChecks = React.memo((props) => {
    const { state, setState } = props; //useContext(JobSheetContext);
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBoxShrink
                        type="date"
                        onChange={setField}
                        value={getField('service24DateRepairCompleted')}
                        label="Date Repair Completed"
                        name="service24DateRepairCompleted"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} item style={{ textAlign: 'left' }}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(getField('service24MaintenanceBookletChecked'))}
                                onChange={setField}
                                name="service24MaintenanceBookletChecked"
                                color="primary"
                            />
                        }
                        label="Maintenance Booklet Checked?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(getField('service24EntitledToMobiloVanServices'))}
                                onChange={setField}
                                name="service24EntitledToMobiloVanServices"
                                color="primary"
                            />
                        }
                        label="Entitled to MobiloVan services?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(getField('service24ReplacementVehicle'))}
                                onChange={setField}
                                name="service24ReplacementVehicle"
                                color="primary"
                            />
                        }
                        label="Replacement Vehicle?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(getField('service24VehicleReturnWithin30Miles'))}
                                onChange={setField}
                                name="service24VehicleReturnWithin30Miles"
                                color="primary"
                            />
                        }
                        label="Vehicle return within 30 miles?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(getField('service24BreakdownOrStartingAssistance'))}
                                onChange={setField}
                                name="service24BreakdownOrStartingAssistance"
                                color="primary"
                            />
                        }
                        label="Breakdown or starting assistance?"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item container spacing={1} xs={12} lg={6}>
                    <Grid item xs={6}>
                        <TextBoxShrink
                            type="number"
                            onChange={setField}
                            value={getField('service24AirTravel')}
                            label="Air Travel Amount"
                            name="service24AirTravel"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxShrink
                            type="number"
                            onChange={setField}
                            value={getField('service24RailTravel')}
                            label="Rail Travel Amount"
                            name="service24RailTravel"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxShrink type="number" onChange={setField} value={getField('service24Taxi')} label="Taxi Travel Amount" name="service24Taxi" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxShrink
                            type="number"
                            onChange={setField}
                            value={getField('service24PublicTransport')}
                            label="Public Transport Travel Amount"
                            name="service24PublicTransport"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxShrink type="number" onChange={setField} value={getField('service24Hotel')} label="Hotel Amount" name="service24Hotel" />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid item xs={12}>
                        <TextBoxShrink multiline minRows={7} onChange={setField} value={getField('service24Notes')} label="Notes" name="service24Notes" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});
