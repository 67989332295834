import { IconButton, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../App/AppContext";
import { DialogContents, Dialogs } from "../../../Core/FormInput";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";

const VideosModal = (props) => {
    const videoDetails = props.videoDetails;

    const handleClose = () => {
        props.closeFunc();
    };

    const getLink = (URL) => {
        if (!URL.includes("youtube.com")) {
            return URL;
        }
        if (URL.includes("youtube.com") && !URL.includes("/embed/")) {
            return "https://www.youtube.com/embed/" + URL.split("?v=")[1]
        }
        return URL;
    }

    return (
        <div>
            <Grid container>
                <Grid xs={11} md={11}>
                    <Breadcrumbs separator=">>" aria-label="breadcrumb">
                        <Link color="inherit" to={"/"}>
                            Home
                        </Link>
                        <Link color="inherit" onClick={handleClose}>
                            Videos
                        </Link>
                        <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                            {videoDetails.videoTitle}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid xs={1} md={1}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container>
                <Grid xs={12} md={12}>
                    <div id="video-player" style={{ height: '80vh', width: '90vw', margin: 'auto' }}>
                        <iframe
                            frameborder="0"
                            allowFullScreen="true"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            width="100%"
                            height="100%"
                            src={getLink(videoDetails.videoURL)}
                            title="Video Player"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default VideosModal;