import React, { useContext } from 'react';
import { Chip, Divider, FormControlLabel, Grid, Hidden, makeStyles, Radio, RadioGroup, FormLabel, Typography, FormHelperText } from '@material-ui/core';
import { get } from 'lodash';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { TextBoxShrink } from '../../../../Core/FormInput';
import AppContext from '../../../../App/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .mauto': {
            margin: 'auto'
        }
    }
}));

export const RepairType = React.memo((props) => {
    const classes = useStyles();
    const { state, setState } = props;
    const { userRoles } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');

    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else if (type === 'radio' && (value === 'true' || value === 'false')) {
            setState((st) => ({ ...st, [name]: value === 'true' }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSign = (name) => {
        setState((st) => ({ ...st, [name]: undefined }));
    };
    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12} md={6}>
                <RadioGroup name="service24TemporaryRepair" value={getField('service24TemporaryRepair')} onChange={setField}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12} className="mauto">
                            <Chip color="secondary" label="A: Temporary Repair" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={getField('service24TemporaryRepair') === true}
                                control={
                                    <Grid container direction="column" style={{ maxWidth: 60 }} alignItems="center">
                                        <Grid item>
                                            <Radio value={true} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="Yes" color="primary" size="small" />
                                        </Grid>
                                    </Grid>
                                }
                                label="The repairs carried out to your vehicle are of temporary nature and it is essential that a permanent repair is carried out as soon as possible."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={getField('service24TemporaryRepair') === false}
                                control={
                                    <Grid container direction="column" style={{ maxWidth: 60 }} alignItems="center">
                                        <Grid item>
                                            <Radio value={false} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="No" color="secondary" size="small" />
                                        </Grid>
                                    </Grid>
                                }
                                label="The Dealer is not liable for any damages or loss arising from the use of the vehicle/component inconsistent with the temporary nature of the repair. This includes liability for consequential loss however caused."
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12} style={{ margin: '20px 0px' }}>
                    <Divider color="secondary" />
                </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <RadioGroup name="service24IncompleteRepair" value={getField('service24IncompleteRepair')} onChange={setField}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12} className="mauto">
                            <Chip color="secondary" label="B: Incomplete Repairs*" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={getField('service24IncompleteRepair') === true}
                                control={
                                    <Grid container direction="column" style={{ maxWidth: 60 }} alignItems="center">
                                        <Grid item>
                                            <Radio value={true} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="Yes" color="primary" size="small" />
                                        </Grid>
                                    </Grid>
                                }
                                label="The repairs carried out to your vehicle are not sufficient for it to be used legally on the road. They are to prevent further damage and to enable recovery of the vehicle."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={getField('service24IncompleteRepair') === false}
                                control={
                                    <Grid container direction="column" style={{ maxWidth: 60 }} alignItems="center">
                                        <Grid item>
                                            <Radio value={false} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="No" color="secondary" size="small" />
                                        </Grid>
                                    </Grid>
                                }
                                label="The above done not release the vehicle to you for use on the road. The customer fully and effectively indemnifies the Dealer against all damage and claims that may arise from the customer taking the vehicle and using it against the advice. This includes liability for consequential loss however caused."
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </Grid>
            <Grid item xs={12}>
                Agreed Para A or B as above
            </Grid>
            {!isAuditor && (
                <Grid item xs={12} sm={12} container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomeSignPad
                            onChange={GetTechnicianSign}
                            content={
                                <FormLabel component="legend" className="mandatoryfields">
                                    Operator Signature
                                    {state.operatorSignedByName && <span> ({` ${state.operatorSignedByName}`})</span>}
                                </FormLabel>
                            }
                            sign={getField('opSignature')}
                            name="service24OperatorSignature"
                            clearSig={() => clearSign('service24OperatorSignature')}
                        />
                        <TextBoxShrink
                            onChange={setField}
                            value={getField('service24OperatorSignedByName')}
                            label="Operator Name"
                            name="service24OperatorSignedByName"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomeSignPad
                            onChange={GetTechnicianSign}
                            content={
                                <FormLabel component="legend" className="mandatoryfields">
                                    Technician Signature
                                    {state.technicianName && <span> ({` ${state.technicianName}`})</span>}
                                </FormLabel>
                            }
                            sign={getField('techSignature')}
                            name="service24TechnicianSignature"
                            clearSig={() => clearSign('service24TechnicianSignature')}
                        />
                        <FormHelperText error>{state.errors?.service24TechnicianSignatureErrorMsg}</FormHelperText>
                        <TextBoxShrink onChange={setField} value={getField('service24TechnicianName')} label="Technician Name" name="service24TechnicianName" />
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1">
                    The Dealer does not exclude liability for death or personal injury arising from its negligence or breach of strict statutory liablitity
                    <br></br>
                    <Typography color="secondary">
                        *Note to Dealer staff: incase of Para B. the vehicle and key are NOT to be handed over to the customer, It must be clear that the
                        operator takes them from the counter/van against your wishes.
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
});
