import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SecondaryButton } from "../../../Core/FormInput/index";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import AppContext from "../../../App/AppContext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AddWorkflowStepsListModal from "./AddUpdateModal";
import { getWorkflowStepList } from "../../../Core/Service/workflowList-service";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import WorkflowStepsListOptions from "./../WorkflowStepsListOption"
import DataGridComp from "../../../Core/Grid";

const WorkflowStepsList = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        WorkflowStepsListDetails: [],
        showOption:false,
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullWorkflowStepsListAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                WorkflowStepsListDetails: params.value.row,
            };
            return nst;
        });
    };
    const handleOptions=(params) => {
        setState((st) => {
            const nst = {
                ...st,
                showOption: true,
                WorkflowStepsListDetails: params.value.row.id,
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Workflow Steps List" aria-label="add">
                    <IconButton
                        size="small"
                        onClick={() => handleUpdate(params)}
                    >
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Workflow Steps List Options" aria-label="add">
                     <IconButton
                        size="small"
                        onClick={() => handleOptions(params)}
                    >
                        <FormatListBulletedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const column_new = [
        {
            field: "id",
            headerName: "ID",
            width: 130,
            sortable: false,
            hide: true,
        },
        {
            field: "",
            headerName: "Action",
            sortable: false,
            width: 110,

            renderCell: (params) => <Arrow value={params} />,
        },
        { field: "listName", headerName: "Description", flex: 2 },
    ];
    const pullWorkflowStepsListAndUpdateState = async () => {
        let result = await getWorkflowStepList();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.map((w) => ({
                    ...w,
                    id: w.listId,
                })),
                show: false,
                showOption:false,
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                WorkflowStepsListDetails: null,
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Workflow Step List Option available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0 }}>
            <SecondaryButton
                variant="contained"
                color="secondary"
                onClick={handleAdd}
                className="btnadd"
            >
                Add Workflow Steps List
            </SecondaryButton>
            </div>
            {state.show ? (
                <AddWorkflowStepsListModal
                    onFormSubmit={pullWorkflowStepsListAndUpdateState}
                    WorkflowStepsListDetails={state.WorkflowStepsListDetails}
                />
            ) : null}
            {state.showOption ? (
                <WorkflowStepsListOptions
                    onFormSubmit={pullWorkflowStepsListAndUpdateState}
                    WorkflowStepsListDetails={state.WorkflowStepsListDetails}
                />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    Workflow Steps List
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default WorkflowStepsList;
