import { get, post } from './http-calls';

export const getService24ByID = async (id) => {
    let res = await get(`Service24Jobs/GetDetails/${id}`, { useAuthToken: true });
    return res;
};

export const getService24Dropdowns = async () => {
    let res = await get(`Service24Jobs/Dropdowns`, { useAuthToken: true });
    return res;
};

export const postService24 = async (data) => {
    let res = await post(`Service24Jobs`, data, { useAuthToken: true });
    return res;
};
