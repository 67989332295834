import React, { useEffect, useState, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../Core/FormInput/index';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { getWebForms } from '../../../Core/Service/workflowList-service';
import AddWebFormModal from './AddWebFormModal';
import DeleteWebFormModal from './DeleteWebForm';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DataGridComp from '../../../Core/Grid';

const WebForm = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        WebFormId: null,
        WebFormDetails: [],
        showDel: false
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullWebFormAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        const WebForm_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                WebFormId: WebForm_id,
                WebFormDetails: params.value.row
            };
            return nst;
        });
    };
    const handleDeleteWebForm = (params) => {
        const WebForm_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                WebFormId: WebForm_id,
                WebFormDetails: params.value.row
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Web Form" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Web Form" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteWebForm(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 110,

            renderCell: (params) => <Arrow value={params} />
        },
        { field: 'name', headerName: 'Name', flex: 0.5 },
        {
            field: 'webFormRelativeUrl',
            headerName: 'URL',
            flex: 0.5
        },
        {
            field: 'webFormButtonLabel',
            headerName: 'Button Label',
            flex: 0.4
        },
        {
            field: 'nonFunctionalDescription',
            headerName: 'Description',
            flex: 1
        }
    ];

    const pullWebFormAndUpdateState = async () => {
        let result = await getWebForms();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.list
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullWebFormAndUpdateState();
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                WebFormId: null,
                WebFormDetails: null
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Web Form available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Web Form
                </SecondaryButton>
            </div>
            {state.show ? <AddWebFormModal WebFormId={state.WebFormId} onFormSubmit={modalClosed} WebFormDetails={state.WebFormDetails} /> : null}
            {state.showDel ? <DeleteWebFormModal WebFormId={state.WebFormId} onFormSubmit={modalClosed} WebFormDetails={state.WebFormDetails} /> : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Web Forms
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default WebForm;
