import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SecondaryButton } from "../../../Core/FormInput/index";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import AppContext from "../../../App/AppContext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AddWorkAccomplishedCodeModal from "./AddUpdateModal";
import { getWorkAccomplishCodes } from "../../../Core/Service/workaccomplished-service";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteWorkAccCodeModal from "./DeleteModal";
import DataGridComp from "../../../Core/Grid";

const WorkAccomplishedCode = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        WorkAccomplishedCodeDetails: [],
        showDel: false,
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullWorkAccomplishedCodeAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                WorkAccomplishedCodeDetails: params.value.row,
            };
            return nst;
        });
    };
    const handleDeleteWebForm = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                WorkAccomplishedCodeDetails: params.value.row,
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Work Accomplished Code" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Work Accomplished Code" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteWebForm(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const column_new = [
        {
            field: "id",
            headerName: "ID",
            width: 130,
            sortable: false,
            hide: true,
        },
        {
            field: "",
            headerName: "Action",
            sortable: false,
            width: 110,

            renderCell: (params) => <Arrow value={params} />,
        },
        {
            field: "workAccomplishedDescription",
            headerName: "Description",
            flex: 2,
        },
    ];
    const pullWorkAccomplishedCodeAndUpdateState = async () => {
        let result = await getWorkAccomplishCodes();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.map((w) => ({
                    ...w,
                    id: w.workAccomplishedCodeID,
                })),
                show: false,
                showDel: false,
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                WorkAccomplishedCodeDetails: null,
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Work Accomplished Code available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Work Accomplished Code
                </SecondaryButton>
            </div>
            {state.show ? (
                <AddWorkAccomplishedCodeModal
                    onFormSubmit={pullWorkAccomplishedCodeAndUpdateState}
                    WorkAccomplishedCodeDetails={state.WorkAccomplishedCodeDetails}
                />
            ) : null}
            {state.showDel ? (
                <DeleteWorkAccCodeModal onFormSubmit={pullWorkAccomplishedCodeAndUpdateState} WorkAccomplishedCodeDetails={state.WorkAccomplishedCodeDetails} />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    Work Accomplished Code
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default WorkAccomplishedCode;
