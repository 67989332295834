import { IconButton } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput/index';
import DataGridComp from '../../../Core/Grid';
import AddBatteryManufacturersModal from './AddUpdateModal';
import { getBatteryManufacturers } from '../../../Core/Service/BatteryManufacturers-service';

const BatteryManufacturers = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        BatteryManufacturersDetails: [],
        showDel: false
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullBatteryManufacturersAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                BatteryManufacturersDetails: params.value.row
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Battery Manufacturers" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id', //batteryManufacturersID
            headerName: 'id',
            flex: 1,
            hide: true
        },
        {
            field: 'batteryManufacturerName', //batteryManufacturersID
            headerName: 'Battery Manufacturer',
            flex: 1
        }
    ];

    const pullBatteryManufacturersAndUpdateState = async () => {
        let result = await getBatteryManufacturers();

        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.list.map((w) => ({
                    ...w,
                    id: w.batteryManufacturerID,
                    batteryManufacturerName: w.batteryManufacturerName
                })),
                show: false,
                showDel: false
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                BatteryManufacturersDetails: null
            };
        });
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Battery Manufacturers Logged</div>
            </GridOverlay>
        );
    }

    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullBatteryManufacturersAndUpdateState();
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Battery Manufacturer
                </SecondaryButton>
            </div>
            {state.show ? (
                <AddBatteryManufacturersModal onFormSubmit={closeUpdateUser} BatteryManufacturersDetails={state.BatteryManufacturersDetails} />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Battery Manufacturer
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default BatteryManufacturers;
