import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../../../Core/Service/login-service';
import AppContext from '../../../App/AppContext';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { TextBox, PasswordBox, SecondaryButton } from '../../../Core/FormInput';
import { Grid, Link } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(0)
    }
}));

function LoginForm(props) {
    const [state, setState] = useState({
        userName: '',
        password: ''
    });
    const { showToast, showModal } = useContext(AppContext);

    const userNameUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, userName: value }));
    };

    const passwordUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, password: value }));
    };
    const handleClose = () => {
        props.history.push('/');
    };
    const handleSubmitClick = async (e) => {
        e.preventDefault();
        if (state.userName && state.password) {
            const res = await login(state.userName, state.password);
            if (!res.success) {
                showToast(res.message);
            } else {
                props.history.push('/');
                window.location.reload();
            }
        } else {
            showToast('User Name and Password both required.');
        }
    };

    const handlePasswordForgot = () => {
        props.history.push('/');
        showModal('ForgotPassword');
    };

    const classes = useStyles();
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Sign In</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <TextBox
                        autoFocus={true}
                        required
                        placeholder="Email"
                        type="email"
                        onChange={userNameUpdated}
                        value={state.userName}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleSubmitClick(event);
                            }
                        }}
                    />
                    <PasswordBox
                        required
                        placeholder="Password"
                        onChange={passwordUpdated}
                        value={state.password}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleSubmitClick(event);
                            }
                        }}
                    />
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmitClick}>
                        Sign In
                    </SecondaryButton>
                    <Grid container>
                        <Grid item xs>
                            <Link component="button" onClick={handlePasswordForgot} variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default LoginForm;
