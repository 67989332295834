import { Grid, FormLabel, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import { getBranchCodes, setBranchCodes } from '../../../Core/Service/branch-screen-service';

const UpdateBranchCodesModal = (props) => {
    const [state, setState] = useState({
        BranchInvoiceEmail: '',
        BranchContractBranchName: '',
        BranchRandMCodes: '',
        BranchWarrantyCodes: '',
        BranchCashCodes: '',
        BranchRectificationAccountCode: '',
        BranchServiceWarrantyWriteOffAccountCode: '',
        BranchPartsWarrantyWriteOffAccountCode: '',
        BranchConsumablesAccountCode: '',
        BranchMobiloVanAccountCode: '',
    });

    const { showToast } = useContext(AppContext);

    const handleSubmit = async () => {
        const data = {
            BranchID: props.branchID,
            BranchInvoiceEmail: state.BranchInvoiceEmail ? state.BranchInvoiceEmail : '',
            BranchContractBranchName: state.BranchContractBranchName ? state.BranchContractBranchName : '',
            BranchRandMCodes: state.BranchRandMCodes ? state.BranchRandMCodes : '',
            BranchWarrantyCodes: state.BranchWarrantyCodes ? state.BranchWarrantyCodes : '',
            BranchCashCodes: state.BranchCashCodes ? state.BranchCashCodes : '',
            BranchRectificationAccountCode: state.BranchRectificationAccountCode ? state.BranchRectificationAccountCode : '',
            BranchServiceWarrantyWriteOffAccountCode: state.BranchServiceWarrantyWriteOffAccountCode ? state.BranchServiceWarrantyWriteOffAccountCode : '',
            BranchPartsWarrantyWriteOffAccountCode: state.BranchPartsWarrantyWriteOffAccountCode ? state.BranchPartsWarrantyWriteOffAccountCode : '',
            BranchConsumablesAccountCode: state.BranchConsumablesAccountCode ? state.BranchConsumablesAccountCode : '',
            BranchMobiloVanAccountCode: state.BranchMobiloVanAccountCode ? state.BranchMobiloVanAccountCode : '',
        }
        let res = await setBranchCodes(data);
        if (!res.success) {
            console.error(res);
            showToast("Error occured.")
        } else {
            showToast("Branch Codes updated successfully")
            props.onClose()
        }
    }

    const pullAndUpdateState = async () => {
        let res = await getBranchCodes(props.branchID);
        if (res.success) {
            const data = res.data[0]
            setState((st) => ({
                ...st,
                BranchInvoiceEmail: data.branchInvoiceEmail,
                BranchContractBranchName: data.branchContractBranchName,
                BranchRandMCodes: data.branchRandMCodes,
                BranchWarrantyCodes: data.branchWarrantyCodes,
                BranchCashCodes: data.branchCashCodes,
                BranchRectificationAccountCode: data.branchRectificationAccountCode,
                BranchServiceWarrantyWriteOffAccountCode: data.branchServiceWarrantyWriteOffAccountCode,
                BranchPartsWarrantyWriteOffAccountCode: data.branchPartsWarrantyWriteOffAccountCode,
                BranchConsumablesAccountCode: data.branchConsumablesAccountCode,
                BranchMobiloVanAccountCode: data.branchMobiloVanAccountCode
            }));
        }
    }

    useEffect(() => {
        pullAndUpdateState();
    }, []);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormLabel component="legend">Invoice Email</FormLabel>
                    <TextBox value={state.BranchInvoiceEmail} name="BranchInvoiceEmail" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Contract Branch Name</FormLabel>
                    <TextBox value={state.BranchContractBranchName} name="BranchContractBranchName" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">RandM Codes</FormLabel>
                    <TextBox value={state.BranchRandMCodes} name="BranchRandMCodes" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Warranty Codes</FormLabel>
                    <TextBox value={state.BranchWarrantyCodes} name="BranchWarrantyCodes" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Cash Codes</FormLabel>
                    <TextBox value={state.BranchCashCodes} name="BranchCashCodes" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Rectification Account Code</FormLabel>
                    <TextBox value={state.BranchRectificationAccountCode} name="BranchRectificationAccountCode" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Service Warranty Write Off Account Code</FormLabel>
                    <TextBox value={state.BranchServiceWarrantyWriteOffAccountCode} name="BranchServiceWarrantyWriteOffAccountCode" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Parts Warranty Write Off Account Code</FormLabel>
                    <TextBox value={state.BranchPartsWarrantyWriteOffAccountCode} name="BranchPartsWarrantyWriteOffAccountCode" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Consumables Account Code</FormLabel>
                    <TextBox value={state.BranchConsumablesAccountCode} name="BranchConsumablesAccountCode" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={6}>
                    <FormLabel component="legend">Mobilo Van Account Code</FormLabel>
                    <TextBox value={state.BranchMobiloVanAccountCode} name="BranchMobiloVanAccountCode" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{ color: 'red' }} variant="p">
                        Note: Codes can be comma seperated i.e (1233,5232)
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default UpdateBranchCodesModal;