import React from 'react';
import { Grid } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const RecoveryDetails = React.memo((props) => {
    const { state, setState } = props; //useContext(JobSheetContext);
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                {/* public bool? Service24Recovered { get; set; }
           public string Service24Status { get; set; }
             public string Service24PaymentTypeID { get; set; } 
            public DateTime?  { get; set; }
        public string Service24TechnicianSignature { get; set; }

                //service24RecoveryDistance
        */}
                <TextBoxShrink onChange={setField} value={getField('service24RecoveryStartLocation')} label="From" name="service24RecoveryStartLocation" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxShrink onChange={setField} value={getField('service24RecoveryEndLocation')} label="To" name="service24RecoveryEndLocation" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxShrink onChange={setField} value={getField('service24RecoveryDistance')} label="Distance (Miles)" name="service24RecoveryDistance" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxShrink
                    onChange={setField}
                    value={getField('service24RecoveryTowingCompany')}
                    label="Towing Company"
                    name="service24RecoveryTowingCompany"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxShrink
                    onChange={setField}
                    value={getField('service24RecoveryTowingContactName')}
                    label="Contact Name"
                    name="service24RecoveryTowingContactName"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextBoxShrink onChange={setField} value={getField('service24RecoveryTowingPhone')} label="Tel No" name="service24RecoveryTowingPhone" />
            </Grid>
        </Grid>
    );
});
