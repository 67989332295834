import { get, post } from './http-calls';

export let addInvoiceCustomer = async (data) => {
    let res = await post(`Customers/Add`, data, { useAuthToken: true });
    return res;
};
export let editInvoiceCustomer = async (data) => {
    let res = await post(`Customers/Update`, data, { useAuthToken: true });
    return res;
};
export let editInvoiceCustomerNote = async (data) => {
    let res = await post(`Customers/UpdateNote`, data, { useAuthToken: true });
    return res;
};
export let addBranchStatusEmails = async (data) => {
    let res = await post(`BranchStatusEmails`, data, { useAuthToken: true });
    return res;
};

export let editBranchStatusEmails = async (data) => {
    let res = await post(`BranchStatusEmails/update`, data, { useAuthToken: true });
    return res;
};

export let getInvoiceCustomerById = async (custId) => {
    let data = await get(`Customers?Id=${custId}`, { useAuthToken: true });
    return data;
};
export let getBranchStatusEmailsById = async (InvoiceStatusId, BranchID) => {
    let data = await get(`BranchStatusEmails/GetById?InvoiceStatusId=${InvoiceStatusId}&BranchID=${BranchID}`, { useAuthToken: true });
    return data;
};
export let deleteBranchStatusEmailsById = async (InvoiceStatusId, BranchID) => {
    let data = await post(`BranchStatusEmails/Delete?InvoiceStatusId=${InvoiceStatusId}&BranchID=${BranchID}`, {}, { useAuthToken: true });
    return data;
};
export let getBranchStatusEmailsDropdown = async () => {
    let data = await get(`BranchStatusEmails/ScreenDropDown`, { useAuthToken: true });
    return data;
};
export let getBranchInvoiceStatusDropdown = async (id) => {
    let data = await get(`Branches/GetBranchInvoiceStatus?BranchID=${id}`, { useAuthToken: true });
    return data;
};
export let getDashboardForInvoice = async (data) => {
    let res = await get(`Invoices/Dashboard`, { useAuthToken: true });
    return res;
};
